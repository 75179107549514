import ServerRequest from '../ServerRequest';

const OrganizationsAPI = {
    /**
     * Получение данных об организации.
     * @return {Promise}
     */
    createOrganization(payload) {
        return ServerRequest.post('/api/organizations/', payload);
    },
    getOrganizationsList() {
        return ServerRequest.get('/api/organizations/');
    },
    deleteOrganizationSection(payload) {
        return ServerRequest.delete(`/api/organizations/${payload.id}/sections/${payload.section}`);
    },
    renameOrganizationSection(payload) {
        return ServerRequest.patch(`/api/organizations/${payload.id}/sections/${payload.section}/`, payload);
    },
};

export default OrganizationsAPI;
