export function getDevicesTreeData(body) {
  return {
    type: 'DEVICES_TREE_FETCH',
    payload: body,
  };
}

export function getDevicesStatData(body) {
  return {
    type: 'DEVICES_STAT_FETCH',
    payload: body,
  };
}

export function clearDevicesData() {
    return {
        type: 'DEVICES_CLEAR_DATA'
    };
}

export function getDevicesStatArchive(body) {
    return {
        type: 'DEVICES_STAT_ARCHIVE_FETCH',
        payload: body,
    };
}

export function getDevicesStatAccessories(body) {
    return {
        type: 'DEVICES_STAT_ACCESSORIES_FETCH',
        payload: body,
    };
}

export function getDevicesSnapshotsData(body) {
	return {
		type: 'DEVICES_SNAPSHOTS_FETCH',
		payload: body,
	};
}

export function getDevicesRemoteData(body) {
    return {
        type: 'DEVICES_REMOTE_FETCH',
        payload: body,
    };
}

export function makeDevicesSnapshots(body) {
	return {
		type: 'DEVICES_MAKE_SNAPSHOTS',
		payload: body,
	};
}

export function makeDevicesActivate(body) {
    return {
        type: 'DEVICES_ACTIVATE',
        payload: body,
    };
}

export function makeDevicesLink(body) {
    return {
        type: 'DEVICES_LINK',
        payload: body,
    };
}

export function makeDevicesShowWindow(body) {
    return {
        type: 'DEVICES_SHOW_LINK',
        payload: body,
    };
}

export function makeDevicesUnLink(body) {
    return {
        type: 'DEVICES_UNLINK',
        payload: body,
    };
}

export function getDevicesWithoutOrganization() {
    return {
        type: 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST',
    };
}

export function getDevicesNotInUse() {
    return {
        type: 'DEVICES_NOT_IN_USE_GET_LIST',
    };
}

export function postDevicesKeysData(body) {
    return {
        type: 'DEVICES_SEND_KEYS',
        payload: body,
    };
}

export function postDevicesClipboardData(body) {
    return {
        type: 'DEVICES_SEND_CLIPBOARD',
        payload: body,
    };
}

export function getDevicesClipboardData(body) {
    return {
        type: 'DEVICES_GET_CLIPBOARD',
        payload: body,
    };
}


export function getDeviceAccessories(body) {
    return {
        type: 'DEVICES_GET_ACCESSORIES',
        payload: body,
    };
}

export function changeStatusDeviceAccessory(body) {
    return {
        type: 'DEVICES_CHANGE_STATUS_ACCESSORIES',
        payload: body,
    };
}

export function changeNameDeviceAccessory(body) {
    return {
        type: 'DEVICES_CHANGE_NAME_ACCESSORIES',
        payload: body,
    };
}