import React, { useState } from 'react';

import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {withStyles} from '@material-ui/core/styles';

import {
    Grid,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";

import {
    KeyboardBackspace,
    Notifications as NotificationsIcon,
    Lock as PasswordIcon
} from '@material-ui/icons';

import MenuAppBar from '../../Components/header'
import UserNotification from '../../Components/user/user-notifications';
import UserPassword from '../../Components/user/user-password';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        textAlign: 'center',
    },
    paper: {
        minHeight: '350px',
    }
});

const Settings = (props) => {

    const {classes, t} = props;
    const [nav, setNav] = useState(1);

    return (
        <div className="reports">
            <MenuAppBar/>
            <Grid container justify="center" className={classes.root}>
                <Grid item xs={12}>
                    <Link to={"/"} className={'backButton'}>
                        <KeyboardBackspace/>
                        {t('Back to terminals')}
                    </Link>
                    <h1 className={classes.title}>
                        {t('Settings')}
                    </h1>
                </Grid>
                <Grid item xs={8}>
                    <Paper className={classes.paper}>
                        <Grid container className={classes.root} spacing={6}>
                            <Grid item xs={3}>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <ListItem
                                        button
                                        onClick={() => setNav(1)}
                                        selected={nav === 1}
                                    >
                                        <ListItemIcon>
                                            <NotificationsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('Notification')} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={() => setNav(2)}
                                        selected={nav === 2}
                                    >
                                        <ListItemIcon>
                                            <PasswordIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('Change Password')} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={9}>
                                {nav === 1 && <UserNotification />}
                                {nav === 2 && <UserPassword />}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    statsAccessories: state.deviceData.statsAccessories,
});

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(Settings);
