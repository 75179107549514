import React, {PureComponent} from 'react';
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import 'react-sortable-tree/style.css';
import {connect} from "react-redux";
import {
    getDeviceAccessories,
    changeStatusDeviceAccessory,
    changeNameDeviceAccessory
} from '../../Actions/device/deviceActions';
import moment from 'moment'

// material ui core
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Table,
    TableBody,
    TableRow,
    TableCell,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemAvatar,
    IconButton,
    Avatar,
    Paper,
    Grid,
    Button,
    Input,
    InputAdornment,
    withStyles
} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
    MoreVert,
    Edit as EditIcon,
    Save as SaveIcon,
    Cancel as CancelIcon
} from "@material-ui/icons";

import './style.scss';
import { changeVarValue } from "../../Actions/other/otherAction";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    button: {
        marginTop: -5,
        marginLeft: 10
    },
});

class Accessories extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentElement: false,
            open: false,
            openNav: false,
            anchorEl: null,
            editName: false,
            nameAccessory: '',
        };
    }

    componentDidMount() {
        const {device} = this.props;
        if (device) {
            this.props.dispatch(getDeviceAccessories(device));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.device
            && this.props.device !== prevProps.device
        ) {
            const {device} = this.props;
            this.props.dispatch(getDeviceAccessories(device));
        }

        if (
            this.props.updateCurrentAccessory
            && this.props.updateCurrentAccessory !== prevProps.updateCurrentAccessory
        ) {
            this.setState({
                currentElement: {
                    ...this.state.currentElement,
                    name: this.state.nameAccessory
                }
            }, () => {
                this.props.dispatch(changeVarValue({
                    name: 'updateCurrentAccessory',
                    value: false,
                }));
            })
        }

        if (
            this.props.accessories
            && this.props.accessories !== prevProps.accessories
        ) {
            setTimeout(() => {
                const {device} = this.props;
                this.props.dispatch(getDeviceAccessories(device));
            }, 3000)
        }
    }

    handleMenu = (elem) => event => {
        this.setState({
            currentElement: elem,
            anchorEl: event.currentTarget,
            openNav: true,
            editName: false,
        });
    };

    clearCurrentAccessory = () => {
        this.setState({
            currentElement: null,
            anchorEl: null,
            open: false,
            openNav: false,
            nameAccessory: '',
        });
    };

    handleClose = () => {
        this.clearCurrentAccessory();
    };

    changeAttachStatus = (uuid, type) => {
        const {device} = this.props;
        this.props.dispatch(changeStatusDeviceAccessory({
            device_id: device,
            accessory: uuid,
            type
        }));
        this.clearCurrentAccessory();
    };

    handleChangeName = (uuid) => {
        const {device} = this.props;
        const {nameAccessory} = this.state;
        this.props.dispatch(changeNameDeviceAccessory({
            device_id: device,
            uuid,
            name: nameAccessory,
        }));
        this.setState({
            editName: false,
        })
    };

    showInfoAccessories = () => {
        this.setState({openNav: false, open: true});
    };

    render() {
        const {anchorEl, openNav, currentElement, editName, nameAccessory} = this.state;
        const {classes, t, accessories} = this.props;

        if(accessories && accessories.length > 0) {
            return (
                <Grid item xs={12} lg={12} className="accessories">
                    <h2>
                        {t('Accessories')}
                    </h2>
                    <Paper elevation={2} style={{paddingTop: 20, paddingBottom: 20}}>
                        <List className={classes.root}>
                            {accessories && accessories.map( (elem, index) => {
                                if (elem.status !== 'unknown') {
                                    return (
                                        <ListItem key={index}>
                                            <ListItemAvatar>
                                                <Avatar className={`accessories__` + elem.status}>
                                                    <span className={`accessories__icon accessories__icon__` + elem.type}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                secondary={t(`accessories_${elem.type}`)}
                                                primary={elem.name}
                                                className={`accessories__` + elem.status + `__text`}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={this.handleMenu(elem)}>
                                                    <MoreVert/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                                return false;
                            })}
                        </List>
                        <h3 style={{paddingLeft: 15, fontSize: 15, fontWeight: 500, color: '#212121'}}>
                            {t('Unknown accessories')}
                        </h3>
                        <List className={classes.root}>
                            {accessories && accessories.map((elem, index) => {
                                if (elem.status === 'unknown') {
                                    return (
                                        <ListItem key={index}>
                                            <ListItemAvatar>
                                                <Avatar className={`accessories__` + elem.status}>
                                                    <span className={`accessories__icon accessories__icon__` + elem.type}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                secondary={t(`accessories_${elem.type}`)}
                                                primary={elem.name}
                                                className={`accessories__` + elem.status + `__text`}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={this.handleMenu(elem)}>
                                                    <MoreVert/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                                return false;
                            })}
                        </List>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openNav}
                            onClose={this.handleClose}
                            className="accessories__nav"
                        >
                            {currentElement &&
                                <MenuItem
                                    onClick={() => this.changeAttachStatus(currentElement.uuid, currentElement.status === 'unknown' ? 'attach' : 'detach')}
                                >
                                    {currentElement.status === 'unknown' ?
                                        <>{t('Attach')}</> : <>{t('Detach')}</>
                                    }
                                </MenuItem>
                            }
                            <MenuItem onClick={this.showInfoAccessories}>
                                {t('More info')}
                            </MenuItem>
                        </Menu>
                    </Paper>
                    {currentElement &&
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                    >
                        <DialogTitle style={{textAlign: 'center'}}>

                            {editName && currentElement.status !== 'unknown' ?
                                <>
                                    <Input
                                        type='text'
                                        value={nameAccessory}
                                        onChange={(e) => this.setState({nameAccessory: e.target.value})}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton size="small" onClick={() => this.handleChangeName(currentElement.uuid)}>
                                                    <SaveIcon/>
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => this.setState({editName: false})}
                                                >
                                                    <CancelIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </>
                            :
                                <>
                                    {currentElement.name}
                                    {currentElement.status !== 'unknown' &&
                                        <IconButton
                                            className={classes.button}
                                            size="small"
                                            onClick={() => this.setState({
                                                editName: true,
                                                nameAccessory: currentElement.name
                                            })}
                                        >
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </>
                            }
                        </DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableBody>
                                    {Object.keys(currentElement).map(key => key === 'name' ? false : (
                                        <TableRow key={key}>
                                            <TableCell component="th" scope="row">
                                                {t(`accessories_${key}`)}
                                            </TableCell>
                                            <TableCell>
                                                {key === 'status' || key === 'type' ?
                                                    t(`accessories_${currentElement[key]}`)
                                                    :
                                                    key === 'connection_date' || key === 'creation_date' ?
                                                        currentElement[key] && moment(currentElement[key]).format('YYYY-MM-DD, HH:mm:ss')
                                                        :
                                                        currentElement[key]
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose}>
                                {t('Close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    }
                </Grid>
            );
        }else{
            return null;
        }
    }
}

const mapStateToProps = state => ({
    accessories: state.deviceData.accessories,
    updateCurrentAccessory: state.otherData.updateCurrentAccessory,
});

const composeComponent = compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(Accessories);

export default composeComponent;
