export function getOrganizations() {
    return {
        type: 'ORGANIZATION_GET_LIST',
    };
}
export function putOrganizations(body) {
    return {
        type: 'ORGANIZATION_CREATE',
        payload: body,
    };
}

export function deleteSectionOrganizations(body) {
    return{
        type: 'ORGANIZATION_SECTION_DELETE',
        payload: body,
    }
}

export function renameSectionOrganizations(body) {
    return{
        type: 'ORGANIZATION_SECTION_RENAME',
        payload: body,
    }
}

export function dontUpdateTreeOrganizations(){
    return{
        type: 'ORGANIZATION_DONT_UPDATE_TREE',
    }
}

export function clearFields() {
    return{
        type: 'CLEAR_FIELDS',
    }
}