export function getUserData(body) {
    return {
        type: 'USER_DATA_FETCH',
        payload: body,
    };
}

export function clearUserData() {
    return {
        type: 'USER_DATA_FETCH_CLEAR',
    };
}

export function UserChangePassword(body) {
    return {
        type: 'USER_CHANGE_PASSWORD',
        payload: body,
    };
}

export function UserClearChangePasswordStatus() {
    return {
        type: 'CLEAR_CHANGE_STATUS_PASSWORD',
    };
}

export function getUserSettingNotifications() {
    return {
        type: 'USER_GET_SETTING_NOTIFICATION',
    };
}

export function postUserSettingNotifications(body) {
    return {
        type: 'USER_POST_SETTING_NOTIFICATION',
        payload: body,
    };
}