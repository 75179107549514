const initialState = {
    profile: {}
};

const otherData = (state = initialState, action) => {
    switch (action.type) {
        case 'NOTIFY_SUCCESS':
            return {
                ...state,
                notification: {
                    message: action.payload.data,
                    type: 'success'
                },
            };
        case 'NOTIFY_ERROR':
            return {
                ...state,
                notification: {
                    message: action.payload.data,
                    type: 'error'
                },
            };
        case 'CHANGE_VAR_VALUE':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        default:
            return state
    }
};

export default otherData;
