import React, {Component} from 'react';
import 'filepond/dist/filepond.min.css';
import MenuAppBar from '../../Components/header'
import SortableTree, {
	getTreeFromFlatData,
	addNodeUnderParent,
	changeNodeAtPath
} from 'react-sortable-tree';
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {ToastContainer} from 'react-toastify';
import 'react-sortable-tree/style.css';
import './files.scss';
import axios from "axios";
import {connect} from "react-redux";
import moment from 'moment';

import {
	renameSectionOrganizations,
} from "../../Actions/organization/organizationAction";



// material ui icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspaceRounded';

// material ui core
import {withStyles} from '@material-ui/core/styles';

import {
	Input,
	Paper,
	Grid,
	IconButton,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	CircularProgress,
	Dialog,
	DialogActions,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@material-ui/core';
import {Link} from "react-router-dom";

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	buttonAction: {
		background: '#6200ee',
	},
	progress: {
		margin: theme.spacing(2),
		color: '#6200ee',
	},
	formControl: {
		minWidth: 180,
	},

	lowerCase: {
		textTransform: 'lowercase',
	}
});

class Files extends Component {
	constructor(props) {
		super(props);
		this.state = {
			treeData: [],
			files: [],
			fileName: '',
			fileSize: '',
			fileMod: '',
			fileCre: '',
			filePath: '',
			fileType: '',
			selectedFile: null,
			fileSendingProcess: 0,
			dialogText: this.props.t('Loading files tree'),
			open: true,
			openConfirm: false,
			download: false,
			deletedSection: ''
		};
	}

	componentDidMount() {
		this.getFilesTree()
	}

	newUpload = () => {
		this.setState({
			fileSendingProcess: 0
		})
	};

	handleClose = () => {
		this.setState({
			open: false,
			openConfirm: false,
		});
	};

	getFilesTree = () => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${localStorage.getItem('DEVICE_ID')}/files/`, {}, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					this.getFilesTreeTask(res.data.location)
				}, err => {
					console.log('err', err);
				}
			)
	};

	fileChangedHandler = event => {
		this.setState({ selectedFile: event.target.files[0] }, () => {
			this.uploadHandler()
		})
	};

	uploadHandler = () => {
		const formData = new FormData();
		formData.append(
			'file',
			this.state.selectedFile,
			this.state.selectedFile.name
		);
		axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${localStorage.getItem('DEVICE_ID')}/upload/`, formData, {
			onUploadProgress: progressEvent => {
				this.setState({
					fileSendingProcess: progressEvent.loaded / progressEvent.total
				})
			},
			headers: {
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		}).then(res => {
			this.uploadHandlerTask(res.data.location)
		})
	};

	uploadHandlerTask = (location) => {
		axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					if (res.data.status === "running") {
						setTimeout(() => {
							this.uploadHandlerTask(location)
						}, 3000)
					} else if (res.data.status === "completed") {
						this.getFilesTree()
					}
				}, err => {
					console.log('err', err);
				}
			)
	};

	getFilesTreeTask = (location) => {
		axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					if (res.data.status === "running") {
						setTimeout(() => {
							this.getFilesTreeTask(location)
						}, 3000)
					} else if (res.data.status === "completed") {
						this.setState({
							// res.data.result
							treeData: getTreeFromFlatData({
								flatData: res.data.result.map(node => ({...node, title: node.name, id: node.path})),
								getKey: node => node.path,
								getParentKey: node => node.parent,
								rootKey: "/",
							})
						}, () => {
							this.handleClose();
							console.log('treeData', this.state.treeData)
						})
					}
				}, err => {
					console.log('err', err);
				}
			)
	};

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	sendChanges = (oldPath, newPath) => {
		axios.patch(`${process.env.REACT_APP_API_URL}/api/devices/${localStorage.getItem('DEVICE_ID')}/files/move/`, {
			old_path: oldPath,
			new_path: newPath,
		}, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
				}, err => {
					console.log('err', err);
				}
			)
	};

	handleConfirmAgree = () => {
		this.setState({
			openConfirm: false,
		}, () => {
			this.deleteSection(this.state.deletedSection)
		} );
	};

	handleDeleteFile = (id) => {
		this.setState({
			openConfirm: true,
			deletedSection: id,
			dialogText: this.props.t('Are you sure you want to delete this item?')
		})
	};

	deleteSection = (id) => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${localStorage.getItem('DEVICE_ID')}/files/delete/`, {
			path: id
		}, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
				this.deleteSectionTask(res.data.location)
				}, err => {
					console.log('err', err);
				}
			)
	};

	downloadFile = (id) => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${localStorage.getItem('DEVICE_ID')}/files/download/`, {
			path: id
		}, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
				this.setState({
					download: true
				}, () => {
					this.downloadFileTask(res.data.location)
				})

				}, err => {
					console.log('err', err);
				}
			)
	};

	downloadFileTask = (location) => {
		axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					if (res.data.status === "running") {
						setTimeout(() => {
							this.downloadFileTask(location)
						}, 3000)
					} else if (res.data.status === "completed") {
						this.setState({
							download: false
						}, () => {
							this.getThisFile(res.data.result.url)
						})

					}
				}, err => {
					console.log('err', err);
				}
			)
	};

	getThisFile = (link) => {
		axios.get(`${process.env.REACT_APP_API_URL}${link}`, {
			headers: {
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			},
			responseType: 'blob',
		})
			.then(res => {
				console.log('getThisFile result', res.data);
				this.showFile(res.data, res.headers)
				}, err => {
					console.log('getThisFile err', err);
				}
			)
	};

	showFile = (data, headers) => {
		let blob = new Blob([data], {type: headers['content-type'] || 'application/octet-stream'});
		if (typeof window.navigator.msSaveBlob !== 'undefined') {
			// IE workaround for "HTML7007: One or more blob URLs were
			// revoked by closing the blob for which they were created.
			// These URLs will no longer resolve as the data backing
			// the URL has been freed."
			window.navigator.msSaveBlob(blob, this.state.fileName);
		}
		else {
			let blobURL = window.URL.createObjectURL(blob);
			let tempLink = document.createElement('a');
			tempLink.style.display = 'none';
			tempLink.href = blobURL;
			tempLink.setAttribute('download', this.state.fileName);

			// Safari thinks _blank anchor are pop ups. We only want to set _blank
			// target if the browser does not support the HTML5 download attribute.
			// This allows you to download files in desktop safari if pop up blocking
			// is enabled.
			if (typeof tempLink.download === 'undefined') {
				tempLink.setAttribute('target', '_blank');
			}

			document.body.appendChild(tempLink);
			tempLink.click();
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(blobURL);
		}
	};

	deleteSectionTask = (location) => {
		axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					if (res.data.status === "running") {
						setTimeout(() => {
							this.deleteSectionTask(location)
						}, 3000)
					} else if (res.data.status === "completed") {
						this.getFilesTree();
						this.setState({
							fileName: '',
							fileSize: '',
							fileMod: '',
							fileCre: '',
							filePath: '',
							fileType: '',
							deletedSection: ''
						})
					}
				}, err => {
					console.log('err', err);
				}
			)
	};

	sendSectionNameChange = (title, id) => {
		this.props.dispatch(renameSectionOrganizations({
			id: this.state.organization,
			section: id,
			name: title,
		}));
	};

	makeNewSection = () => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${localStorage.getItem('DEVICE_ID')}/files/mkdir/`, {
			name: 'New section',
			path: '/'

		}, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
				this.makeNewSectionTask(res.data.location)
				}, err => {
					console.log('err', err);
				}
			)
	};

	makeNewSectionTask = (location) => {
		axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					if (res.data.status === "running") {
						setTimeout(() => {
							this.makeNewSectionTask(location)
						}, 3000)
					} else if (res.data.status === "completed") {
						this.getFilesTree()
					}
				}, err => {
					console.log('err', err);
				}
			)
	};


	sizeOf = (bytes) => {
		if (bytes === 0) { return "0.00 B"; }
		let e = Math.floor(Math.log(bytes) / Math.log(1024));
		return (bytes/Math.pow(1024, e)).toFixed(2)+' '+' KMGTP'.charAt(e)+'B';
	};

	render() {
		const recordCall = (name, args) => {
			this.setState({
				newParent: args.nextParentNode === null ? null : args.nextParentNode.id,
				changedSection: args.node.id,
			}, () => {
				this.sendChanges(args.node.path, `${args.nextParentNode === null ? '/' : args.nextParentNode.path}/${args.node.title}`)
			})
		};

		const {classes, t} = this.props;
		const getNodeKey = ({treeIndex}) => treeIndex;

		return (
			<div className="files">
				<MenuAppBar/>
				<Grid container
				      spacing={6} className={classes.root}>
					<Grid item xs={12}
						  style={{margin: '0 auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 18}}>
						<Link to={"/"} className={'backButton'}>
							<KeyboardBackspace className={classes.icon}/>
							{t('Back to terminals')}
						</Link>
					</Grid>
					<Grid item xs={12} md={8} className="files__left">
						<Grid>
							<h2>{t('File Manager')}</h2>
							<Paper elevation={2} style={{paddingTop: 20, paddingBottom: 20, overflow: 'hidden'}}>
								<div style={{height: 500}}>
									<SortableTree
										treeData={this.state.treeData}
										onChange={treeData => this.setState({treeData})}
										dndType={'tree'}
										canDrop={({nextParent}) => !nextParent || !nextParent.noChildren}
										canNodeHaveChildren={node => node.type !== 'file'}
										onMoveNode={args => {
											recordCall('onMoveNode', args);
											const {prevPath, nextPath, node} = args;
											this.setState({
												lastMovePrevPath: prevPath,
												lastMoveNextPath: nextPath,
												lastMoveNode: node,
											});
										}}
										generateNodeProps={({node, path}) => (
											node.type === 'file' && !this.state.editActive ? {
												className: 'file',
												onClick: () => {
													this.setState({
														fileName: node.title,
														fileType: node.file_type,
														filePath: node.path,
														fileSize: node.size,
														fileMod: node.modified,
														fileCre: node.created,
													})
												},
												id: node.id,
											} : this.state.editActive && node.type === 'dir' ? {
												className: 'dir',
												buttons: [
													<IconButton aria-label="Create new folder" onClick={() => {
														this.makeNewSection(`New section`, node.id);
														this.setState(state => ({
															treeData: addNodeUnderParent({
																treeData: state.treeData,
																parentKey: path[path.length - 1],
																expandParent: true,
																getNodeKey,
																newNode: {
																	title: `New Section`,
																	type: 'dir'
																},
																addAsFirstChild: state.addAsFirstChild,
															}).treeData,
														}))
													}
													}>
														<CreateNewFolder/>
													</IconButton>,
													<IconButton aria-label="Delete" onClick={() => {
														this.handleDeleteFile(node.path)
													}
													}>
														<DeleteIcon/>
													</IconButton>,
													<IconButton aria-label="Create new folder"
													            style={{borderRadius: '7px'}}
													            onClick={() => {
														            let midArr = node.path.split('/');
														            midArr.pop();
														            const newPath = midArr.join('/');
														            this.sendChanges(node.path, `${newPath}/${node.title}`)
													            }
													            }>
														<SaveIcon/>
														<span style={{
															display: 'inline-block',
															fontSize: 14,
															paddingLeft: '10px'
														}}>
																{t('Save')}
															</span>
													</IconButton>
												], title: (
													<Input
														style={{fontSize: '1.1rem'}}
														value={node.title}
														onChange={event => {
															const title = event.target.value;
															this.setState(state => ({
																treeData: changeNodeAtPath({
																	treeData: state.treeData,
																	path,
																	getNodeKey,
																	newNode: {...node, title},
																}),
															}));
														}}
													/>
												)
											} : this.state.editActive && node.type === 'file' ? {
												className: 'file',
												buttons: [
													<IconButton aria-label="Delete" onClick={() => {
														this.handleDeleteFile(node.path)
													}
													}>
														<DeleteIcon/>
													</IconButton>,
													<IconButton aria-label="Create new folder"
													            style={{borderRadius: '7px'}}
													            onClick={() => {
														            let midArr = node.path.split('/');
														            midArr.pop();
														            const newPath = midArr.join('/');
														            this.sendChanges(node.path, `${newPath}/${node.title}`)
													            }
													            }>
														<SaveIcon/>
														<span style={{
															display: 'inline-block',
															fontSize: 14,
															paddingLeft: '10px'
														}}>
																{t('Save')}
															</span>
													</IconButton>
												],
												title: (
													<Input
														style={{fontSize: '1.1rem'}}
														value={node.title}
														onChange={event => {
															const title = event.target.value;
															this.setState(state => ({
																treeData: changeNodeAtPath({
																	treeData: state.treeData,
																	path,
																	getNodeKey,
																	newNode: {...node, title},
																}),
															}));
														}}
													/>
												)
											} : node.type === 'dir' ? {
												className: 'dir',
											} : ''
										)}
									/>
								</div>
								<div className="files__left__bottom" style={{textAlign: 'right', paddingRight: '20px'}}>
									<IconButton aria-label="Create new folder" style={{borderRadius: '7px'}}
									            onClick={() => {
										            this.makeNewSection(`New section`, null);
										            this.setState(state => ({
											            treeData: state.treeData.concat({
												            title: `New section`,
												            type: 'dir'
											            }),
										            }))
									            }
									            }>
										<CreateNewFolder/>
										<span style={{display: 'inline-block', fontSize: 14, paddingLeft: '10px'}}>
												{t('Add folder')}
										</span>
									</IconButton>
									{!this.state.editActive ?
										<IconButton aria-label="Create new folder" style={{borderRadius: '7px'}}
										            onClick={() =>
											            this.setState({
												            editActive: !this.state.editActive
											            })
										            }>
											<EditIcon/>
											<span style={{
												display: 'inline-block',
												fontSize: 14,
												paddingLeft: '10px'
											}}>
												{t('Edit mode')}
										</span>
										</IconButton> :
										<IconButton aria-label="Create new folder" style={{borderRadius: '7px'}}
										            onClick={() =>
											            this.setState({
												            editActive: !this.state.editActive
											            })
										            }>
											<VisibilityIcon/>
											<span style={{
												display: 'inline-block',
												fontSize: 14,
												paddingLeft: '10px'
											}}>
												{t('View mode')}
											</span>
										</IconButton>
									}
								</div>
							</Paper>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						<Grid>
							<h2>{t('File info')}</h2>
							<Paper elevation={2} className={'file-block'}>
								{this.state.fileName ?
									<div>
										<div className='file-img'/>
										<Table className='info_file'>
											<TableBody>
												<TableRow>
													<TableCell component="th" scope="row">
														{t('File name')}
													</TableCell>
													<TableCell>
														{this.state.fileName}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell component="th" scope="row">
														{t('File type')}
													</TableCell>
													<TableCell>
														{this.state.fileType}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell component="th" scope="row">
														{t('File size')}
													</TableCell>
													<TableCell>
														{this.sizeOf(this.state.fileSize)}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell component="th" scope="row">
														{t('Modified date')}
													</TableCell>
													<TableCell>
														{
															moment(this.state.fileMod).subtract(10, 'days').calendar()
														}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell component="th" scope="row">
														{t('Created date')}
													</TableCell>
													<TableCell>
														{
															moment(this.state.fileCre).subtract(10, 'days').calendar()
														}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
										<div className={'buttons-group'}>

											<IconButton aria-label="Delete" style={{color: 'red'}} onClick={() => {this.handleDeleteFile(this.state.filePath)}
											}>
												<DeleteIcon/>
											</IconButton>
											<a href="/#" id="save-file" style={{visibility: 'hidden'}} download>download</a>
											{ this.state.download ?
											<CircularProgress className={classes.progress}/>
											: <Button variant="contained" color="secondary" onClick={() => {this.downloadFile(this.state.filePath)}} className={classes.buttonAction}>
													{t('Download file')}
											</Button>
											}
										</div>
									</div>
									: <h3>{t('Click on file')}</h3>
								}
							</Paper>
							{
								this.state.fileSendingProcess === 0 ?
									<input type="file" onChange={this.fileChangedHandler} className="uploadbutton"/>
									: this.state.fileSendingProcess > 0 && this.state.fileSendingProcess < 1 ?
									<div className="uploadStatusWrap">
										<div className="uploadStatus"
											 style={{width: `${this.state.fileSendingProcess * 100}%`}}>
										</div>
									</div>
									: this.state.fileSendingProcess === 1 ?
										<div className="uploadStatusSuccess" onClick={this.newUpload}>
											<h3>{t('File upload success')}</h3>
											<p>{t('Click to upload new')}</p>
										</div>
										:
										<input type="file" onChange={this.fileChangedHandler} className="uploadbutton"/>
							}
						</Grid>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{this.state.dialogText}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description" style={{
							textAlign: 'center', margin: '20px' +
								' auto !important'
						}}>
							<CircularProgress className={classes.progress}/>
						</DialogContentText>
					</DialogContent>
				</Dialog>
				<Dialog
					open={this.state.openConfirm}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{this.state.dialogText}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{this.state.dialogContent}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="contained" color="secondary" onClick={this.handleClose}>
							{t('Disagree')}
						</Button>
						<Button variant="contained" onClick={() => {
							this.handleConfirmAgree(this.state.dialogConfirmType)
						}} autoFocus style={{backgroundColor: 'green', color: "white"}}>
							{t('Agree')}
						</Button>
					</DialogActions>
				</Dialog>
				<ToastContainer/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	device: state.deviceData.device,
	user: state.userData.user,
	organizationsList: state.organizationsData.organizationsList,
	updateTree: state.organizationsData.updateTree,
	listUnmountedPos: typeof state.deviceData.listUnmountedPos === 'object' ? Object.values(state.deviceData.listUnmountedPos) : [],
});
const composeComponent = compose(
	connect(mapStateToProps),
	withStyles(styles),
	withNamespaces(),
)(Files);

export default composeComponent;


