import ServerRequest from '../ServerRequest';

const UserAPI = {
	/**
	 * Получение данных о пользователе.
	 * @return {Promise}
	 */
	getUserData(payload) {
		return ServerRequest.post('/api/users/login/', payload);
	},
	createUser(payload){
		return ServerRequest.post('/api/users/', payload);
	},
	changePasswordUser(payload){
		return ServerRequest.post('/api/users/change-password/', payload);
	},
	getUserSettingNotifications(){
		return ServerRequest.get('/api/users/settings/notification/');
	},
	postUserSettingNotifications(payload){
		return ServerRequest.patch('/api/users/settings/notification/', payload);
	}
};

export default UserAPI;
