import React, {Component, Fragment} from 'react';
import MenuAppBar from '../../Components/header'
import Paper from "@material-ui/core/Paper/Paper";
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspaceRounded';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    Grid,
    TextField,
} from "@material-ui/core";
import {Area, AreaChart, ResponsiveContainer, Tooltip} from "recharts";
import {getDevicesStatArchive} from "../../Actions/device/deviceActions";
import moment from 'moment'
import './style.scss';
import ResponsiveTable from 'material-ui-next-responsive-table'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    progress: {
        margin: theme.spacing(2),
        color: '#6200ee',
    },
    title: {
        textAlign: 'center',
    },
});


class Report extends Component {


    constructor(props) {
        super(props);

        this.state = {
            device: localStorage.getItem('DEVICE_ID'),
            deviceTitle: localStorage.getItem('DEVICE_TITLE'),
            selectedDateStart: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            selectedDateEnd: moment().format('YYYY-MM-DD'),
        };
    }

    handleDateStartChange = e => {
        this.setState({ selectedDateStart: e.target.value });
    };

    handleDateEndChange = e => {
        this.setState({ selectedDateEnd: e.target.value });
    };

    componentDidMount() {
        this.getStats();
    }

    getStats = () => {
        this.props.dispatch(getDevicesStatArchive({
            device_id: this.state.device,
            start_date: moment(this.state.selectedDateStart).utc(true).toISOString(),
            end_date: moment(this.state.selectedDateEnd).utc(true).endOf('day').toISOString(),
        }));
    };

    render() {
        const {classes, t} = this.props;
        const {selectedDateStart, selectedDateEnd, deviceTitle} = this.state;

        const columns = [
            {
                key: 'time',
                label: t('Date'),
                primary: true,
            },
            {
                key: 'cpu',
                label: 'CPU (%)',
                render: (value) => (value.percent).toFixed(1),
            },
            {
                key: 'hdd',
                label: `HDD (${t('used')} GB)`,
                render: (value) => (value.used/1024).toFixed(1),
            },
            {
                key: 'hdd',
                label: `HDD (${t('free')} GB)`,
                render: (value) => (value.free/1024).toFixed(1),
            },
            {
                key: 'memory',
                label: `${t('Memory')} (${t('used')} GB)`,
                render: (value) => (value.used/1024).toFixed(1),
            },
            {
                key: 'memory',
                label: `${t('Memory')} (%)`,
                render: (value) => (value.percent).toFixed(1),
            },
        ];

        return (
            <div className="reports">
                <MenuAppBar/>
                <Grid container spacing={6} className={classes.root}>
                    <Grid item xs={12}
                          style={{margin: '0 auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 18}}>
                        <Link to={"/"} className={'backButton'}>
                            <KeyboardBackspace/>
                            {t('Back to terminals')}
                        </Link>
                        <h1 className={classes.title}>
                            {t('Reports')}
                        </h1>
                        { deviceTitle && (
                            <h2 className={classes.title}>
                                {t('Device name')}: <strong>{ deviceTitle }</strong>
                            </h2>
                        ) }
                    </Grid>

                        <Grid item xs={12}>
                            <div className="reports__filter">
                                <TextField
                                    label={t('Date start')}
                                    type="date"
                                    value={selectedDateStart}
                                    className="reports__filter_field"
                                    onChange={this.handleDateStartChange}
                                />

                                <TextField
                                    label={t('Date end')}
                                    type="date"
                                    value={selectedDateEnd}
                                    className="reports__filter_field"
                                    onChange={this.handleDateEndChange}
                                />
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => this.getStats()}
                                >
                                    {t('Apply')}
                                </Button>
                            </div>
                        </Grid>
                    {!!this.props.stats && !!(this.props.stats.total).length ?
                        <Fragment>
                            <Grid item xs={12} md={4}>
                                <Paper
                                    elevation={2}
                                    style={{
                                        paddingTop: 1,
                                        paddingLeft: 14,
                                        paddingRight: 14,
                                        paddingBottom: 14,
                                        position: 'relative'
                                    }}
                                    className={'cpu-card'}
                                >
                                    <div>
                                        <h2>CPU (GHz)</h2>
                                        <div className="chart-container">
                                            <ResponsiveContainer>
                                                <AreaChart data={this.props.stats.cpu}
                                                           margin={{
                                                               top: 10,
                                                               right: 0,
                                                               left: 0,
                                                               bottom: 0
                                                           }}>
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient id="colorCpu" x1="0" y1="0"
                                                                        x2="1" y2="0">
                                                            <stop offset="5%" stopColor="#9EE77F"
                                                                  stopOpacity={0.6}/>
                                                            <stop offset="95%" stopColor="#2DEFDB"
                                                                  stopOpacity={0.8}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <Area type='monotone' dataKey='percent'
                                                          stroke='#2DEFDB'
                                                          fill='url(#colorCpu)'/>
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper
                                    elevation={2}
                                    style={{
                                        paddingTop: 1,
                                        paddingLeft: 14,
                                        paddingRight: 14,
                                        paddingBottom: 14,
                                        position: 'relative'
                                    }}
                                    className={'cpu-card'}
                                >
                                    <div>
                                        <h2>HDD (GB)</h2>
                                        <div className="chart-container">
                                            <ResponsiveContainer>
                                                <AreaChart data={this.props.stats.hdd}
                                                           margin={{
                                                               top: 10,
                                                               right: 0,
                                                               left: 0,
                                                               bottom: 0
                                                           }}>
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient id="colorCpu" x1="0" y1="0"
                                                                        x2="1" y2="0">
                                                            <stop offset="5%" stopColor="#9EE77F"
                                                                  stopOpacity={0.6}/>
                                                            <stop offset="95%" stopColor="#2DEFDB"
                                                                  stopOpacity={0.8}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <Area type='monotone' dataKey='percent'
                                                          stroke='#2DEFDB'
                                                          fill='url(#colorCpu)'/>
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper
                                    elevation={2}
                                    style={{
                                        paddingTop: 1,
                                        paddingLeft: 14,
                                        paddingRight: 14,
                                        paddingBottom: 14,
                                        position: 'relative'
                                    }}
                                    className={'cpu-card'}
                                >
                                    <div>
                                        <h2>{t('Memory')} (GB)</h2>
                                        <div className="chart-container">
                                            <ResponsiveContainer>
                                                <AreaChart data={this.props.stats.memory}
                                                           margin={{
                                                               top: 10,
                                                               right: 0,
                                                               left: 0,
                                                               bottom: 0
                                                           }}>
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient id="colorCpu" x1="0" y1="0"
                                                                        x2="1" y2="0">
                                                            <stop offset="5%" stopColor="#9EE77F"
                                                                  stopOpacity={0.6}/>
                                                            <stop offset="95%" stopColor="#2DEFDB"
                                                                  stopOpacity={0.8}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <Area type='monotone' dataKey='percent'
                                                          stroke='#2DEFDB'
                                                          fill='url(#colorCpu)'/>
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>

                                <Paper
                                    elevation={2}
                                    style={{
                                        paddingTop: 1,
                                        paddingLeft: 14,
                                        paddingRight: 14,
                                        paddingBottom: 14,
                                        position: 'relative'
                                    }}
                                >
                                    <h2 className={classes.title}>
                                        {t('Total stats')}
                                    </h2>
                                    {!!this.props.stats.total ?
                                        <ResponsiveTable
                                            columns={columns}
                                            data={this.props.stats.total}
                                        />
                                        :
                                        <h3 className={classes.title}>
                                            {t('Missing data')}
                                        </h3>
                                    }
                                </Paper>
                            </Grid>
                        </Fragment>
                        :
                        <Grid item xs={12}>
                            <h2 className={classes.title}>
                                {t('Missing data')}
                            </h2>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    stats: state.deviceData.stats,
});

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(Report);
