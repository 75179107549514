import {call, put, all, takeLatest} from 'redux-saga/effects';
import ServerRequest from '../../services/ServerRequest';
import UserAPI from '../../services/rest-api/UserAPI';
import {types} from '../types';

import * as _ from 'lodash';

export function* userSaga() {
    yield all([
        userDataFlow(),
    ]);
}

function redirectTo(redirectLocation) {
    window.location.hash = redirectLocation
}

function* userDataFlow() {
    yield takeLatest('USER_DATA_FETCH', getRequisites);
    yield takeLatest('USER_CHANGE_PASSWORD', userPostPassword);
    yield takeLatest('USER_CREATE', createUser);
    yield takeLatest('USER_GET_SETTING_NOTIFICATION', getUserSettingNotifications);
    yield takeLatest('USER_POST_SETTING_NOTIFICATION', postUserSettingNotifications);
}

function* getRequisites(action) {
    try {
        const response = yield call(UserAPI.getUserData, action.payload);
        yield put({
            type: 'USER_DATA_FETCH_SUCCESS',
            payload: {
                data: _.get(response, 'data', {}),
            },
        });
        yield localStorage.setItem('USER_JWT', _.get(response, 'data.token', {}));
        yield localStorage.setItem('role', _.get(response, 'data.user.role', {}));
        yield localStorage.setItem('email', action.payload.email);
        yield redirectTo('/');
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);
        yield put({
            type: types.NOTIFY_ERROR,
            payload: {
                data: 'authorization_error',
            }
        });
        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({type: 'USER_DATA_FETCH_ERROR'});
        }
    }
}

function* userPostPassword(action) {
    try {
        yield call(UserAPI.changePasswordUser, action.payload);

        yield put({type: 'USER_CHANGE_PASSWORD_SUCCESS'});

    } catch (error) {
        const body = error.response.data;
        const keys = Object.keys(body);
        const errorText = body[keys[0]][0];

        yield put({
            type: 'USER_CHANGE_PASSWORD_ERROR',
            payload: {
                data: errorText,
            },
        });

        yield put({
            type: types.NOTIFY_ERROR,
            payload: {
                data: 'Change password failed',
            }
        });
    }
}

function* createUser(id, data) {
    try {

        let organization = {'organization' : id};

        yield call(UserAPI.createUser, {...organization, ...data});

        yield put({
            type: 'USER_CREATE_SUCCESS',
        });

    }catch (error) {
        yield put({type: 'USER_CREATE_ERROR'});
    }
}

function* getUserSettingNotifications() {
    try {
        const response = yield call(UserAPI.getUserSettingNotifications);
        yield put({
            type: 'USER_GET_SETTING_NOTIFICATION_SUCCESS',
            payload: {
                data: _.get(response, 'data', {}),
            },
        });
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'USER_GET_SETTING_NOTIFICATION_ERROR' });
        }
    }
}

function* postUserSettingNotifications(action) {
    try {
        yield call(UserAPI.postUserSettingNotifications, action.payload);
        yield put({
            type: 'USER_POST_SETTING_NOTIFICATION_SUCCESS',
        });
        yield put({
            type: types.NOTIFY_SUCCESS,
            payload: {
                data: 'Save success',
            }
        });
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'USER_POST_SETTING_NOTIFICATION_ERROR' });
            yield put({
                type: types.NOTIFY_ERROR,
                payload: {
                    data: 'Save error',
                }
            });
        }
    }
}