import React, {Component} from 'react';
import MenuAppBar from '../../Components/header'
import Paper from "@material-ui/core/Paper/Paper";
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspaceRounded';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    Grid,
    MenuItem,
    Select,
    TextField,
    FormControl,
    InputLabel,
    Input
} from "@material-ui/core";
import {getDevicesStatAccessories} from "../../Actions/device/deviceActions";
import moment from 'moment'
import './style.scss';
import ResponsiveTable from 'material-ui-next-responsive-table'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    progress: {
        margin: theme.spacing(2),
        color: '#6200ee',
    },
    title: {
        textAlign: 'center',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});


class Report extends Component {


    constructor(props) {
        super(props);

        this.state = {
            device: localStorage.getItem('DEVICE_ID'),
            deviceTitle: localStorage.getItem('DEVICE_TITLE'),
            selectedDateStart: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            selectedDateEnd: moment().format('YYYY-MM-DD'),
            accessory: '',
            type: '',
        };
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount() {
        this.getStats();
    }

    getStats = () => {
        this.props.dispatch(getDevicesStatAccessories({
            device_id: this.state.device,
            start_date: moment(this.state.selectedDateStart).utc(true).toISOString(),
            end_date: moment(this.state.selectedDateEnd).utc(true).endOf('day').toISOString(),
            accessory: this.state.accessory,
            type: this.state.type,
        }));
    };

    render() {
        const {classes, t, statsAccessories} = this.props;
        const {
            selectedDateStart,
            selectedDateEnd,
            type, accessory,
            deviceTitle
        } = this.state;

        const columns = [
            {
                key: 'name',
                label: t('accessories_name'),
                primary: true,
            },
            {
                key: 'accessory_type',
                label: t('accessories_type'),
                render: (value) => t(`accessories_${value}`),
            },
            {
                key: 'model',
                label: t('accessories_model'),
            },
            {
                key: 'vendor',
                label: t('accessories_vendor'),
            },
            {
                key: 'type',
                label: t('accessories_action_type'),
                render: (value) => t(`accessories_${value}`),
            },
            {
                key: 'creation_date',
                label: t('accessories_date'),
                render: (value) => moment(value).format('YYYY-MM-DD, HH:mm:ss'),
            },
        ];

        return (
            <div className="reports">
                <MenuAppBar/>
                <Grid container spacing={6} className={classes.root}>
                    <Grid item xs={12}
                          style={{margin: '0 auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 18}}>
                        <Link to={"/"} className={'backButton'}>
                            <KeyboardBackspace/>
                            {t('Back to terminals')}
                        </Link>
                        <h1 className={classes.title}>
                            {t('Reports accessories')}
                        </h1>
                        { deviceTitle && (
                            <h2 className={classes.title}>
                                {t('Device name')}: <strong>{ deviceTitle }</strong>
                            </h2>
                        ) }
                    </Grid>
                    <Grid item xs={12}>
                        <div className="reports__filter">
                            <FormControl className={classes.formControl}>
                                <TextField
                                    label={t('Date start')}
                                    type="date"
                                    name="selectedDateStart"
                                    value={selectedDateStart}
                                    className="reports__filter_field"
                                    onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    label={t('Date end')}
                                    type="date"
                                    name="selectedDateEnd"
                                    value={selectedDateEnd}
                                    className="reports__filter_field"
                                    onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField
                                    label={t('accessories_uuid')}
                                    type='text'
                                    name='accessory'
                                    value={accessory}
                                    className="reports__filter_field"
                                    onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink htmlFor="type-label-placeholder">
                                    {t('accessories_action_type')}
                                </InputLabel>
                                <Select
                                    value={type}
                                    onChange={this.handleChange}
                                    input={<Input name="type" id="type-label-placeholder" />}
                                    displayEmpty
                                    name="type"
                                    className={classes.selectEmpty}
                                >
                                    <MenuItem value="" selected>{t('Not selected')}</MenuItem>
                                    <MenuItem value='attached'>{t('accessories_attached')}</MenuItem>
                                    <MenuItem value='detached'>{t('accessories_detached')}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => this.getStats()}
                            >
                                {t('Apply')}
                            </Button>
                            </FormControl>
                        </div>
                    </Grid>
                    {statsAccessories && statsAccessories.length > 0 ?
                        <>
                            <Grid item xs={12}>

                                <Paper elevation={2} style={{
                                    paddingTop: 1,
                                    paddingLeft: 14,
                                    paddingRight: 14,
                                    paddingBottom: 14,
                                    position: 'relative'
                                }}>
                                    <h2 className={classes.title}>
                                        {t('Total stats')}
                                    </h2>
                                    <ResponsiveTable
                                        columns={columns}
                                        data={statsAccessories}
                                    />
                                </Paper>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <h2 className={classes.title}>
                                {t('Missing data')}
                            </h2>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    statsAccessories: state.deviceData.statsAccessories,
});

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(Report);
