import React from "react";
import NoVNC from "../no-vnc";
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    Switch,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    ListSubheader,
    TextField,
    withStyles
} from "@material-ui/core";
import {
    Fullscreen as FullScreenIcon,
    ErrorOutline as ErrorIcon,
    PhonelinkErase as DisconnectIcon,
    Mouse as MouseIcon,
    Keyboard as KeyboardIcon,
    FileCopy as FileCopyIcon

} from '@material-ui/icons';
import {compose} from "redux";
import {withNamespaces} from "react-i18next";
import {
    getDevicesRemoteData,
    postDevicesClipboardData,
    getDevicesClipboardData,
} from "../../Actions/device/deviceActions";
import {
    changeVarValue
} from "../../Actions/other/otherAction";
import connect from "react-redux/es/connect/connect";
import Fullscreen from "react-full-screen";
import './vnc.scss'

const Actions = (props) => {
    return (
        <div id='disconnect'>
            {/*<button id='disconnect-btn' onClick={props.onDisconnect}>Disconnect</button>*/}
        </div>
    )
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    progress: {
        color: '#6200ee',
    },
    error:{
        fontSize: '120px',
    },
    extraKeysText:{
        paddingLeft: 0,
        paddingRight: 0
    },
    textField:{
        minWidth: 200,
        width: '100%',
        marginBottom: 20
    },
    button: {
        margin: theme.spacing(1),
    },
    cssLabel: {
        '&$cssFocused': {
            color: 'rgba(0, 0, 0, 0.87)',
        },
    },
    cssFocused: {},
    cssUnderline: {
        '&:after': {
            borderBottomColor: 'rgba(0, 0, 0, 0.87)',
        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
        },
    },
    notchedOutline: {},
    bootstrapRoot: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    listItemIcon: {
        minWidth: 35
    }
});

class Vnc extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            connection: false,
            open: true,
            openClipboard: false,
            viewOnly: true,
            isFull: false,
            disconnect: false,
            openLeftNav: true,
            openLeftNavKeys: false,
            errorConnect: false,
            callKey: '',
            clipBoard: '',
        };
    }

    componentDidMount() {
        this.connectToVNC();
    }

    connectToVNC = () => {
        this.props.dispatch(getDevicesRemoteData({
            device_id: localStorage.getItem('DEVICE_ID'),
            view_only: this.state.viewOnly
        }))
    };

    sendClipboard = () => {
        this.props.dispatch(postDevicesClipboardData({
            device_id: localStorage.getItem('DEVICE_ID'),
            text: this.state.clipBoard,
        }));
    };

    getClipboard = () => {
        this.props.dispatch(getDevicesClipboardData({
            device_id: localStorage.getItem('DEVICE_ID'),
        }));
    };

    onBeforeConnect = () => {
        this.setState({
            open: false,
        })
    };

    onDisconnected = (error = true) => {
        this.setState({
            disconnect: true,
            errorConnect: !!error,
            open: false,
            isFull: false,
        })
    };

    handleClickOpen = (name) => {
        this.setState({
            [name]: true,
            callKey: '',
        });
        if(name === 'openClipboard'){
            this.setState({isFull: false});
        }
    };

    handleClose = (name) => {
        this.setState({[name]: false});
    };

    handleChange = (name, type) => event => {
        switch (type) {
            case 'radio':
                this.setState({[name]: event.target.checked});
                break;
            case 'text':
            default:
                this.setState({[name]: event.target.value});
                break;
        }
        this.setState({
            callKey: '',
        });
        if (name === 'viewOnly') {
            this.connectToVNC();
        }
    };

    componentDidUpdate (prevProps) {
        if (
            this.props.clipBoardSendSuccess
            && this.props.clipBoardSendSuccess !== prevProps.clipBoardSendSuccess
        ) {
            this.setState({
                openClipboard: false,
                clipBoard: ''
            }, () => {
                this.props.dispatch(changeVarValue({
                    name: 'clipBoardSendSuccess',
                    value: false,
                }));
            })
        }
        if (
            this.props.clipboard
            && this.props.clipboard !== prevProps.clipboard
        ) {
            this.setState({
                clipBoard: this.props.clipboard
            }, () => {
                this.props.dispatch(changeVarValue({
                    name: 'clipboard',
                    value: '',
                }));
            })
        }
    }

    handleOpenNav = () => {
        this.setState({
            openLeftNav: !this.state.openLeftNav,
            openLeftNavKeys: false,
            callKey: '',
        });
    };

    goFull = () => {
        this.setState({isFull: true});
    };

    render() {
        const {classes, t} = this.props;
        const connection = !!this.props.device.remote && !this.state.disconnect;
        console.log(34, this.props.device);
        return (
            <div className='vnc'>
                <div>
                    {connection ?
                        <Grid container
                              spacing={8} className={classes.root}>
                            <Grid item xs={12}>
                                <Fullscreen
                                    enabled={this.state.isFull}
                                    onChange={isFull => this.setState({isFull})}
                                >
                                    <List
                                        subheader={<ListSubheader style={{position: "relative"}}>{t('Settings')}</ListSubheader>}
                                        className={'leftNav ' + (!this.state.openLeftNav ? 'close' : '') + (this.state.openLeftNavKeys ? 'open_keys' : '')}
                                    >
                                        <ListItem>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <MouseIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary={t('View only')} />
                                            <ListItemSecondaryAction>
                                                <Switch
                                                    checked={this.state.viewOnly}
                                                    onChange={this.handleChange('viewOnly','radio')}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        {!this.state.viewOnly &&
                                            <>
                                                <ListItem>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <KeyboardIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText inset primary={t('Extra Keys')}/>
                                                    <ListItemSecondaryAction>
                                                        <Switch
                                                            checked={this.state.openLeftNavKeys}
                                                            onChange={this.handleChange('openLeftNavKeys','radio')}
                                                        />
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <ListItem button onClick={() => this.handleClickOpen('openClipboard')}>
                                                    <ListItemIcon className={classes.listItemIcon}>
                                                        <FileCopyIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText inset primary={t('Clipboard')}/>
                                                </ListItem>
                                            </>
                                        }
                                        {!this.state.isFull &&
                                            <ListItem button onClick={this.goFull}>
                                                <ListItemIcon className={classes.listItemIcon}>
                                                    <FullScreenIcon/>
                                                </ListItemIcon>
                                                <ListItemText inset primary={t('Fullscreen')}/>
                                            </ListItem>
                                        }
                                        <ListItem button onClick={() => this.onDisconnected(false)}>
                                            <ListItemIcon className={classes.listItemIcon}>
                                                <DisconnectIcon/>
                                            </ListItemIcon>
                                            <ListItemText inset primary={t('Disconnect')}/>
                                        </ListItem>
                                        <div
                                            className='leftNav__after'
                                            onClick={this.handleOpenNav}
                                        />
                                        <List
                                            subheader={<ListSubheader className={classes.extraKeysText}>{t('Extra Keys')}</ListSubheader>}
                                            className={'leftNav_keys ' + (this.state.openLeftNavKeys ? 'open' : '')}
                                        >
                                            <ListItem
                                                button
                                                onClick={() => this.setState({callKey : 'windows'})}
                                                id='noVNC_toggle_windows_button'
                                                className='noVNC_toggle_button'
                                            >
                                                    <span className="icon"/>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.setState({callKey : 'ctrl'})}
                                                id='noVNC_toggle_ctrl_button'
                                                className='noVNC_toggle_button'
                                            >
                                                <span className="icon"/>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.setState({callKey : 'alt'})}
                                                id='noVNC_toggle_alt_button'
                                                className='noVNC_toggle_button active'
                                            >
                                                <span className="icon"/>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.setState({callKey : 'tab'})}
                                                id='noVNC_toggle_tab_button'
                                                className='noVNC_toggle_button active'
                                            >
                                                <span className="icon"/>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.setState({callKey : 'esc'})}
                                                id='noVNC_toggle_esc_button'
                                                className='noVNC_toggle_button active'
                                            >
                                                <span className="icon"/>
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() => this.setState({callKey : 'ctrlaltdel'})}
                                                id='noVNC_toggle_ctrlaltdel_button'
                                                className='noVNC_toggle_button active'
                                            >
                                                <span className="icon"/>
                                            </ListItem>
                                        </List>
                                    </List>
                                    <NoVNC
                                        connectionName={this.props.device.remote.url}
                                        // connectionName={this.props.device.remote.url.replace("wss://", "")}
                                        actionsBar={(props) => <Actions onDisconnect={props.onDisconnect}/>}
                                        password={this.props.device.remote.password}
                                        onBeforeConnect={this.onBeforeConnect}
                                        viewOnly={this.state.viewOnly}
                                        onDisconnected={this.onDisconnected}
                                        isFullScreen={this.state.isFull}
                                        callKey={this.state.callKey}
                                    />
                                </Fullscreen>
                            </Grid>
                        </Grid>
                        :
                        <div>
                            {this.state.disconnect &&
                            <Grid container
                                  spacing={6} className={classes.root}  justify="center">
                                <Grid item xs={12} lg={6}>
                                    <Paper elevation={2} className="control__error">
                                        {this.state.errorConnect ?
                                            <div>
                                                <ErrorIcon className={classes.error} color="error"/>
                                                <Typography variant="h2" gutterBottom collor="error" className="control__error__title">
                                                    {t('Connection Error')}
                                                </Typography>
                                                <Button onClick={() => this.setState({disconnect: false})}>
                                                    {t('Reconnect')}
                                                </Button>
                                            </div>
                                            :
                                            <div>
                                                <DisconnectIcon className={classes.error} />
                                                <Typography variant="h2" gutterBottom collor="error" className="control__error__title">
                                                    {t('Disconnected')}
                                                </Typography>
                                                <Button onClick={() => this.setState({disconnect: false})}>
                                                    {t('Connect')}
                                                </Button>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                            }
                        </div>
                    }
                </div>
                <>
                    <Dialog
                        open={this.state.open}
                        onClose={() => this.handleClose('open')}
                    >
                        <DialogTitle>{t('Connecting')}...</DialogTitle>
                        <DialogContent style={{
                            textAlign: 'center', margin: '20px' +
                                ' auto !important'
                        }}>
                            <CircularProgress className={classes.progress}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openClipboard}
                        onClose={() => this.handleClose('openClipboard')}
                    >
                        <DialogTitle style={{textAlign: "center"}}>{t('Clipboard')}</DialogTitle>
                        <DialogContent>
                            <Grid container style={{paddingTop: 0}}>
                                <Grid item xs={12}>
                                    <TextField
                                        label={t('Clipboard')}
                                        onChange={this.handleChange('clipBoard','text')}
                                        value={this.state.clipBoard}
                                        multiline
                                        className={classes.textField}
                                        margin="normal"
                                        variant="outlined"
                                        rows={5}
                                        InputLabelProps={{
                                            classes: {
                                                root: classes.cssLabel,
                                                focused: classes.cssFocused,
                                            },
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: classes.cssOutlinedInput,
                                                focused: classes.cssFocused,
                                                notchedOutline: classes.notchedOutline,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" onClick={this.getClipboard} className="resize_button">
                                        {t('Get')}
                                    </Button>
                                    <Button variant="contained" onClick={this.sendClipboard} style={{float: "right"}} className="resize_button">
                                        {t('Post')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    device: state.deviceData.device,
    clipBoardSendSuccess: state.otherData.clipBoardSendSuccess,
    clipboard: state.otherData.clipboard,
});

export default compose(
    withNamespaces(),
    withStyles(styles),
    connect(mapStateToProps),
)(Vnc);
