const organizationsData = (state = {}, action) => {
    switch (action.type) {
        case 'ORGANIZATION_CREATE_SUCCESS':
            return  {
                ...state,
                organization: {
                    id: action.payload.data,
                    statusOrganization: 'Success',
                }
            };
        case 'ORGANIZATION_CREATE_ERROR':
            return  {
                ...state,
                organization: {
                    statusOrganization: 'Error',
                }
            };
        case 'ORGANIZATION_GET_LIST_SUCCESS':
            return  {
                ...state,
                organizationsList: action.payload.data

            };
        case 'ORGANIZATION_GET_LIST_ERROR':
            return  {
                ...state,
            };
        case 'CLEAR_FIELDS_SUCCESS':
            return  {
                ...state,
                organization: {
                    statusOrganization: '',
                }
            };
        case 'ORGANIZATION_SECTION_DELETE_SUCCESS':
            return  {
                ...state,
                updateTree: true,
            };
        case 'ORGANIZATION_SECTION_DELETE_ERROR':
            return  {
                ...state,
                updateTree: false,
            };
        case 'ORGANIZATION_DONT_UPDATE_TREE':
            return {
                ...state,
                updateTree: false,
            };
        case 'ORGANIZATION_UPDATE_TREE':
            return {
                ...state,
                updateTree: true,
            };
        default:
            return state
    }
};

export default organizationsData;
