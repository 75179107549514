import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {HashRouter} from 'react-router-dom'
import {Provider} from 'react-redux';
import store from './store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './i18n';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#fff'
		}
	},
	overrides: {
		MuiAppBar: {
			root: {
				borderBottom: '1px solid #ccc'
			}
		},
		MuiTypography: {
		root: {
			display: 'block',
			width: '100%'
		}
		},
		MuiExpansionPanelSummary: {
			content: {
				flexWrap: 'wrap'
			},
			'&$expanded': {
				backgroundColor: '#EFE5FD'
			},

		},
		ListDividers: {
			root: {
				maxWidth: '100%',
			}
		},
		MuiListItemText: {
			inset: {
				paddingLeft: '0 !important'
			}
		},
		MuiGrid: {
			container: {
				maxWidth: '96%',
				paddingTop: '20px',
				margin: '0 auto !important'
			}
		},
		MuiPaper: {
			rounded: {
				borderRadius: '8px'
			},
			elevation4: {
				boxShadow: 'none'
			},
			elevation1: {
				boxShadow: 'none'
			},
			elevation2: {
				boxShadow: '0 2px 25px 0 rgba(0,0,0, 0.1)'
			},
		},
		MuiToolbar: {
			root: {
				paddingTop: '10px',
				paddingBottom: '10px',
			},
			gutters: {
				paddingLeft: '40px !important',
				paddingRight: '40px !important',
			}
		},
		MuiFormControl: {
			fullWidth: {
				width: '100% !important'
			}
		},
		MuiDrawer: {
			docked: {
				background: '#333b4e',
				minHeight: '100vh',
				borderRight: '20px solid #eeeff4'
			},
		},
		MuiMenuItem: {
			root: {
				color: '#848ca7 !important',
				opacity: 1,
				paddingTop: '16px',
				paddingBottom: '16px'
			},
		},
		MuiIconButton: {
			root: {
				borderRadius: '0 !important',
			},
		},
		MuiButton: {// override the styles of all instances of this component
			root: { // Name of the rule
				borderRadius: '0 !important',
				fontWeight: 600,
			},
			outlined: {
				background: 'none !important',
				color: '#000 !important',
				border: '2px solid #6202EE !important'
			},
			containedPrimary: {
				boxShadow: 'none',
				color: 'white'
			}
		},
	}
});
ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider theme={theme}>
			<HashRouter>
				<App/>
			</HashRouter>
		</MuiThemeProvider>
	</Provider>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
