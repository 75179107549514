import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DevicesList from "../../devicesList";
import { connect } from "react-redux";
import {
    getDevicesNotInUse,
    getDevicesWithoutOrganization,
    makeDevicesLink,
    makeDevicesShowWindow,
} from "../../../Actions/device/deviceActions";

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    grid: {
        paddingTop: 0,
    }
};

class DashboardBottom extends Component{

    state = {
        modalOpen: false,
        modalTitle: '',
        listUnmountedPos: [],
        listNotInUsePos: [],
        organization: null,
    };

    componentDidMount() {
        if (localStorage.getItem('role') === 'super-admin') {
            this.getPosWithoutOrganization();
            this.getNotUsePos();
            this.getOrganization();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(
            localStorage.getItem('ORGANIZATION_ID') &&
            Number(localStorage.getItem('ORGANIZATION_ID')) !== this.state.organization
        ){
            this.getOrganization();
        }
    }


    getOrganization = () => {
        this.setState({
            organization: localStorage.getItem('ORGANIZATION_ID') ? Number(localStorage.getItem('ORGANIZATION_ID')) : ''
        })
    };

    getPosWithoutOrganization = () => {
        this.props.dispatch(getDevicesWithoutOrganization());
    };
    getNotUsePos = () => {
        this.props.dispatch(getDevicesNotInUse());
    };

    confirmAction = (elem, title, text, method) => {
        this.setState({
            modalTitle: typeof title !== 'function' ? title : title(elem),
            modalText: text,
            modalElem: elem,
            modalOpen: true,
            modalMethod: method,
        });
    };

    handleConfirmAgree = () => {
        const { modalMethod, organization, modalElem } = this.state;
        if (modalMethod === 'linkDevice') {
            this.setState({
                modalOpen: false,
            });
            this.props.dispatch(makeDevicesLink({
                organization,
                deviceId: modalElem.udid
            }))
        }else if (modalMethod === 'showOpenDevice') {
            this.setState({
                modalOpen: false,
            });
            this.props.dispatch(makeDevicesShowWindow({
                show_login: !modalElem.show_login,
                deviceId: modalElem.udid
            }))
        }
    };

    showWindowTitle = elem =>{
        const { t } = this.props;
        if(!elem.show_login){
            return t('Show login window');
        }else{
            return t('Hide login window');
        }
    };

    showWindowConfirmText = elem =>{
        const { t } = this.props;
        if(!elem.show_login){
            return t('Show window confirm');
        }else{
            return t('Hide window confirm');
        }
    };

    render() {
        const { t } = this.props;
        const { organization } = this.state;
        const buttonLink = {
            title: t('Bind'),
            confirmText: t('Bind device confirm'),
            buttonFunc: this.confirmAction,
            type: 'linkDevice'
        };
        const buttonShow = {
            title: this.showWindowTitle,
            confirmText: this.showWindowConfirmText,
            buttonFunc: this.confirmAction,
            type: 'showOpenDevice'
        };

        let actions = [];
        if(organization){
            actions.push(buttonLink);
        }

        actions.push(buttonShow);

        return (
            <>
                { this.props.listUnmountedPos.length ?
                    <Grid item xs={ 12 } lg={ 6 }>
                        <Grid>
                            <h2>{ t('Without organization') }</h2>
                            <DevicesList
                                items={ this.props.listUnmountedPos }
                                showSerial={ true }
                                actions={ actions }
                            />
                        </Grid>
                    </Grid>
                    : '' }
                { this.props.listNotInUsePos.length ?
                    <Grid item xs={ 12 } lg={ 6 }>
                        <Grid>
                            <h2>{ t('Non distributed devices') }</h2>
                            <DevicesList
                                items={ this.props.listNotInUsePos }
                                showSerial={ false }
                            />
                        </Grid>
                    </Grid>
                    : '' }
                {this.state.modalElem &&
                    <Dialog
                        open={ this.state.modalOpen }
                        onClose={ () => this.setState({ modalOpen: false }) }
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{ this.state.modalTitle } {t('device')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                { this.state.modalText } {this.state.modalElem.name}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={ { justifyContent: 'space-between', margin: '15px 20px'} }>
                            <Button variant="contained" color="secondary"
                                    onClick={ () => this.setState({ modalOpen: false }) }>
                                { t('Disagree') }
                            </Button>
                            <Button variant="contained" onClick={ this.handleConfirmAgree } autoFocus
                                    style={ { backgroundColor: 'green', color: "white" } }>
                                { t('Agree') }
                            </Button>
                        </DialogActions>
                    </Dialog>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    listUnmountedPos: typeof state.deviceData.listUnmountedPos === 'object' ? Object.values(state.deviceData.listUnmountedPos) : [],
    listNotInUsePos: typeof state.deviceData.listNotInUsePos === 'object' ? Object.values(state.deviceData.listNotInUsePos) : [],
});

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(DashboardBottom);
