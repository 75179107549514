import ServerRequest from '../ServerRequest';

const DeviceApi = {
	/**
	 * Получение данных о устройствах.
	 * @return {Promise}
	 */
	getDevicesTreeData(payload) {
		return ServerRequest.get(`/api/organizations/${payload}/sections/tree/`);
	},
	getDeviceStatTask(payload) {
		return ServerRequest.post(`/api/devices/${payload}/stat/full/`);
	},
	getDeviceStatList(payload) {
		return ServerRequest.get(`${payload}`);
	},
	getDeviceSnapshotsTask(payload) {
		return ServerRequest.post(`/api/devices/${payload}/snapshots/`);
	},
	getDeviceSnapshotsList(payload) {
		return ServerRequest.get(`${payload}`);
	},
	makeDeviceSnapshotTask(payload) {
		return ServerRequest.post(`/api/devices/${payload.id}/snapshots/create/`, payload.data);
	},
	makeDeviceActivate(payload) {
		return ServerRequest.post(`/api/devices/${payload}/activate/`);
	},
	makeDevicesLink(payload) {
		return ServerRequest.post(`/api/devices/${payload.deviceId}/link/?organization_id=${payload.organization}`);
	},
	makeDevicesUnLink(payload) {
		return ServerRequest.post(`/api/devices/${payload}/unlink/`);
	},
	makeDevicesShowWindow(payload) {
		return ServerRequest.patch(`/api/devices/${payload.deviceId}/`, {show_login: payload.show_login});
	},
	makeDeviceSnapshot(payload) {
		return ServerRequest.get(`${payload}`);
	},
	getDeviceRemoteTask(payload) {
		return ServerRequest.post(`/api/devices/${payload.device_id}/remote_control/`, payload);
	},
	getDeviceeRemote(payload) {
		return ServerRequest.get(`${payload}`);
	},
	getDevicesStatsArchive(payload) {
		return ServerRequest.get(`/api/devices/${payload.device_id}/stat/archive/`, payload);
	},
	// start - Acceessories
	getDevicesStatsAccessories(payload) {
		return ServerRequest.get(`/api/accessories/logs/`, payload);
	},
	getDeviceAccessoriesTask(payload) {
		return ServerRequest.post(`/api/devices/${payload}/accessories/`);
	},
	getDeviceAccessories(payload) {
		return ServerRequest.get(`${payload}`);
	},
	attachStatusDeviceAccessory(payload) {
		return ServerRequest.post(`/api/accessories/config/add/`, payload);
	},
	detachStatusDeviceAccessory(payload) {
		return ServerRequest.post(`/api/accessories/config/delete/`, payload);
	},
	patchDeviceAccessory(payload) {
		return ServerRequest.patch(`/api/accessories/?device_id=${payload.device_id}`, payload);
	},
	// end - Acceessories

	postDevicesKeysData(payload) {
		return ServerRequest.post(`/api/devices/${payload.device_id}/press_keys/`, payload);
	},
	postDevicesClipboardData(payload) {
		return ServerRequest.post(`/api/devices/${payload.device_id}/clipboard/update/`, {text: payload.text});
	},
	getDevicesClipboardDataTask(payload) {
		return ServerRequest.post(`/api/devices/${payload.device_id}/clipboard/`);
	},
	getDevicesClipboardData(payload) {
		return ServerRequest.get(`${payload}`);
	},
	getDevicesWithoutOrganization() {
		return ServerRequest.get('/api/devices/?organization=null&not_in_use=false');
	},
	getDevicesNotInUse() {
		return ServerRequest.get('/api/devices/?not_in_use=1');
	},
};

export default DeviceApi;
