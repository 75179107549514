import i18n from "i18next";
import {reactI18nextModule} from "react-i18next";

import translationEN from './locales/en/translation';
import accessoriesEN from './locales/en/accessories';

import translationRU from './locales/ru/translation';
import accessoriesRU from './locales/ru/accessories';


const RU = Object.assign(
    translationRU,
    accessoriesRU
);

const EN = Object.assign(
    translationEN,
    accessoriesEN
);

// the translations
const resources = {
    en: {
        translation: EN
    },
    ru: {
        translation: RU
    }
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('lng') || "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;