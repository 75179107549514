const initialState = {
	user: {
		loginData: {},
		loginStatus: '',
		settings: {
			notifications: {
				all_accessory_actions: false,
				accessory_disconnect: false,
				windows_online: false,
				windows_offline: false,
				start: '00:00',
				end: '00:00',
				alternative_email: '',
				days: [false, false, false, false, false, false, false],
				timezone: 0
			},
		},
	}
};

const userData = (state = initialState, action) => {
	switch (action.type) {
		case 'USER_DATA_FETCH_SUCCESS':
			return  {
				...state,
				user: {
					...state.user,
					loginData: action.payload.data,
					loginStatus: 'Success'
				}
			};
		case 'USER_SINGIN_REQUEST':
			return  {
				...state,
				user: {
					...state.user,
					loginStatus: 'SingIn'
				}
			};
		case 'USER_DATA_FETCH_ERROR':
			return  {
				...state,
				user: {
					...state.user,
					loginData: action.payload,
					loginStatus: 'Error'
				}
			};
		case 'USER_CHANGE_PASSWORD_SUCCESS':
			return  {
				...state,
				user: {
					...state.user,
					changePasswordStatus: 'Success',
				}
			};
		case 'USER_CHANGE_PASSWORD_ERROR':
			return  {
				...state,
				user: {
					...state.user,
					changePasswordStatus: 'Error',
					changePasswordStatusText: action.payload.data
				}
			};
		case 'CLEAR_CHANGE_STATUS_PASSWORD':
			return  {
				...state,
				user: {
					...state.user,
					changePasswordStatus: '',
				}
			};
		case 'USER_DATA_FETCH_CLEAR':
			return  {
				...state,
				user: {
					...state.user,
					loginStatus: ''
				}
			};
		case 'USER_CREATE_SUCCESS':
			return  {
				...state,
				organization: {
					...state.organization,
					statusUser: 'Success',
				}
			};
		case 'USER_CREATE_ERROR':
			return  {
				...state,
				organization: {
					statusUser: 'Error',
				}
			};
		case 'USER_GET_SETTING_NOTIFICATION_SUCCESS':
			return  {
				...state,
				user: {
					...state.user,
					settings: {
						...state.user.settings,
						notifications: action.payload.data,
					}
				}
			};

		default:
			return state
	}
};

export default userData
