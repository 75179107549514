import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import './App.scss';
import Dashboard from './Pages/dashboard';
import Control from './Pages/control';
import Backup from './Pages/backup';
import Login from './Pages/login';
import Create from './Pages/create';
import Files from './Pages/files';
import Report from  './Pages/report';
import reportAccessories from  './Pages/reportAccessories';
import Setting from  './Pages/settings';

class App extends Component {
  render() {
    return (
      <div className="App">
	      <Switch>
		      <Route exact path='/' component={Dashboard}/>
		      <Route exact path='/control' component={Control}/>
		      <Route exact path='/backup' component={Backup}/>
		      <Route exact path='/login' component={Login}/>
		      <Route exact path='/create' component={Create}/>
		      <Route exact path='/files' component={Files}/>
		      <Route exact path='/report' component={Report}/>
		      <Route exact path='/report-accessories' component={reportAccessories}/>
		      <Route exact path='/settings' component={Setting}/>
	      </Switch>
      </div>
    );
  }
}

export default App;
