import {all, call, put, takeLatest} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import ServerRequest from '../../services/ServerRequest';
import DeviceAPI from '../../services/rest-api/DeviceApi';
import * as _ from 'lodash';
import {types} from '../types';

export function* deviceSaga() {
  yield all([deviceDataFlow()]);
}

function* deviceDataFlow() {
  yield takeLatest('DEVICES_TREE_FETCH', getTree);
  yield takeLatest('DEVICES_STAT_FETCH', getStatTask);
  yield takeLatest('DEVICES_STAT_ARCHIVE_FETCH', getStatArchive);
  yield takeLatest('DEVICES_STAT_ACCESSORIES_FETCH', getStatAccessories);
  yield takeLatest('DEVICES_SNAPSHOTS_FETCH', getSnapshotsTask);
  yield takeLatest('DEVICES_REMOTE_FETCH', getRemoteTask);
  yield takeLatest('DEVICES_MAKE_SNAPSHOTS', makeSnapshotsTask);
  yield takeLatest('DEVICES_ACTIVATE', makeActivate);
  yield takeLatest('DEVICES_LINK', makeDevicesLink);
  yield takeLatest('DEVICES_UNLINK', makeDevicesUnLink);
  yield takeLatest('DEVICES_SHOW_LINK', makeDevicesShowWindow);
  yield takeLatest('DEVICES_WITHOUT_ORGANIZATION_GET_LIST', getDevicesWithoutOrganization);
  yield takeLatest('DEVICES_NOT_IN_USE_GET_LIST', getDevicesNotInUse);
  yield takeLatest('DEVICES_SEND_KEYS', postDevicesKeysData);
  yield takeLatest('DEVICES_SEND_CLIPBOARD', postDevicesClipboardData);
  yield takeLatest('DEVICES_GET_CLIPBOARD', getDevicesClipboardDataTask);
  yield takeLatest('DEVICES_GET_ACCESSORIES', getDeviceAccessoriesTask);
  yield takeLatest('DEVICES_CHANGE_STATUS_ACCESSORIES', changeStatusDeviceAccessory);
  yield takeLatest('DEVICES_CHANGE_NAME_ACCESSORIES', changeNameDeviceAccessory);
}

function* getTree(action) {
  try {
    const response = yield call(DeviceAPI.getDevicesTreeData, action.payload);
    yield put({
      type: 'DEVICES_TREE_FETCH_SUCCESS',
      payload: {
        data: _.get(response, 'data', {}),
      },
    });

  } catch (error) {
    const errorAction = ServerRequest.errorHandler(error);

    if (errorAction) {
      yield put(errorAction);
    } else {
      yield put({ type: 'DEVICES_TREE_FETCH_ERROR' });
    }
  }
}
let saveResp = '';
function* getStatTask(action) {
	try {
		if (action.payload.type === "first") {
			const response = yield call(DeviceAPI.getDeviceStatTask, action.payload.id);
			saveResp = yield response;
			yield  getStatList(_.get(response, 'data.location', {}));
		} else if (action.payload.type === "again") {

			yield  getStatList(_.get(saveResp, 'data.location', {}));
		}
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);
		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICE_STAT_FETCH_ERROR' });
		}
	}
}

function* getStatList(location) {
	try {
		const stat = yield call(DeviceAPI.getDeviceStatList, location);
		yield put({ type: 'DEVICES_STAT_TASK_FETCH_SUCCESS' });
		yield put({
					type: 'DEVICE_STAT_FETCH_SUCCESS',
					payload: {
						data: _.get(stat, 'data', {}),
					},
				})
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICE_STAT_FETCH_ERROR' });
		}
	}
}
let saveRespSnap = '';
function* getSnapshotsTask(action) {
	try {
		if (action.payload.type === "first") {
			const response = yield call(DeviceAPI.getDeviceSnapshotsTask, action.payload.id);
			saveRespSnap = yield response;
			yield  getSnapshotsList(_.get(response, 'data.location', {}));
		} else if (action.payload.type === "again") {
			yield  getSnapshotsList(_.get(saveRespSnap, 'data.location', {}));
		}
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICE_SNAPSHOTS_FETCH_ERROR' });
		}
	}
}

function* getSnapshotsList(location) {
	try {

		const stat = yield call(DeviceAPI.getDeviceSnapshotsList, location);
		yield put({ type: 'DEVICES_SNAPSHOTS_TASK_FETCH_SUCCESS' });
		yield delay(500);
		yield put({
			type: 'DEVICE_SNAPSHOTS_FETCH_SUCCESS',
			payload: {
				data: _.get(stat, 'data', {}),
			},
		})
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICE_SNAPSHOTS_FETCH_ERROR' });
		}
	}
}


function* getRemote(location) {
	try {
		let response = yield call(DeviceAPI.getDeviceeRemote, location);
		while (response.data.status !== 'completed'){
			yield delay(100);
			response = yield call(DeviceAPI.getDeviceeRemote, location);
		}
		if(response.data.status === 'completed'){
			yield put({
				type: 'DEVICE_REMOTE_DATA_SUCCESS',
				payload: {
					data: response.data.result,
				},
			})
		}

	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICE_REMOTE_DATA_ERROR' });
		}
	}


}

function* getRemoteTask(action) {
	try {
		let response = yield call(DeviceAPI.getDeviceRemoteTask, action.payload);
		yield getRemote(_.get(response, 'data.location', {}));
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICE_REMOTE_FETCH_ERROR' });
		}
	}
}

function* makeSnapshotsTask(action) {
	try {
		const response = yield call(DeviceAPI.makeDeviceSnapshotTask, action.payload);
		yield  makeSnapshots(_.get(response, 'data.location', {}));
		// yield getStatList(_.get(response, 'data.location', {}))
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_MAKE_SNAPSHOTS_ERROR' });
		}
	}
}

function* makeActivate(action) {
    try {
        yield call(DeviceAPI.makeDeviceActivate, action.payload);
        yield put({
            type: 'DEVICES_ACTIVATE_SUCCESS',
        });
        yield put({
            type: types.NOTIFY_SUCCESS,
            payload: {
                data: 'Device successfully activated',
            }
        });
		yield put({
			type: 'ORGANIZATION_UPDATE_TREE',
		});
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);
        yield put({
            type: types.NOTIFY_ERROR,
            payload: {
                data: 'Device not activated',
            }
        });
        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_ACTIVATE_ERROR' });
        }
    }
}

function* makeDevicesLink(action) {
    try {
        yield call(DeviceAPI.makeDevicesLink, action.payload);
        yield put({
            type: 'DEVICES_LINK_SUCCESS',
        });
        yield put({
            type: types.NOTIFY_SUCCESS,
            payload: {
                data: 'Device binding successful',
            }
        });
		yield put({
			type: 'ORGANIZATION_UPDATE_TREE',
		});
		yield put({
			type: 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST',
		});
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);
        yield put({
            type: types.NOTIFY_ERROR,
            payload: {
                data: 'Device binding not successful',
            }
        });
        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_LINK_ERROR' });
        }
    }
}

function* makeDevicesUnLink(action) {
    try {
        yield call(DeviceAPI.makeDevicesUnLink, action.payload);
        yield put({
            type: 'DEVICES_UNLINK_SUCCESS',
        });
        yield put({
            type: types.NOTIFY_SUCCESS,
            payload: {
                data: 'Device unbinding successful',
            }
        });
        yield put({
            type: 'ORGANIZATION_UPDATE_TREE',
        });
        yield put({
            type: 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST',
        });
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);
        yield put({
            type: types.NOTIFY_ERROR,
            payload: {
                data: 'Device unbinding not successful',
            }
        });
        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_UNLINK_ERROR' });
        }
    }
}

function* makeDevicesShowWindow(action) {
    try {
        yield call(DeviceAPI.makeDevicesShowWindow, action.payload);
        yield put({
            type: 'DEVICES_SHOW_LINK_SUCCESS',
        });
        yield put({
            type: types.NOTIFY_SUCCESS,
            payload: {
                data: 'Login window status changed successfully',
            }
        });
        yield put({
            type: 'ORGANIZATION_UPDATE_TREE',
        });
        yield put({
            type: 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST',
        });
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);
        yield put({
            type: types.NOTIFY_ERROR,
            payload: {
                data: 'Login window status changed not successfully',
            }
        });
        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_SHOW_LINK_ERROR' });
        }
    }
}

function* makeSnapshots(location) {
	try {
		const stat = yield call(DeviceAPI.makeDeviceSnapshot, location);
		yield delay(500);
		yield put({
			type: 'DEVICE_MAKE_SNAPSHOTS_SUCCESS',
			payload: {
				data: _.get(stat, 'data', {}),
			},
		})
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_MAKE_SNAPSHOTS_ERROR' });
		}
	}
}

function* getDevicesWithoutOrganization(action) {
    try {
        const response = yield call(DeviceAPI.getDevicesWithoutOrganization, action.payload);
        yield put({
            type: 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST_SUCCESS',
            payload: {
                data: _.get(response, 'data.items', {}),
            },
        });

    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST_ERROR' });
        }
    }
}

function* getDevicesNotInUse(action) {
    try {
        const response = yield call(DeviceAPI.getDevicesNotInUse, action.payload);
        yield put({
            type: 'DEVICES_NOT_IN_USE_GET_LIST_SUCCESS',
            payload: {
                data: _.get(response, 'data.items', {}),
            },
        });

    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_NOT_IN_USE_GET_LIST_ERROR' });
        }
    }
}

function* getStatArchive(action) {
	try {
		const response = yield call(DeviceAPI.getDevicesStatsArchive, action.payload);
		yield put({
			type: 'DEVICES_STAT_ARCHIVE_FETCH_SUCCESS',
			payload: {
				data: _.get(response, 'data', {}),
			},
		});

	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_STAT_ARCHIVE_FETCH_ERROR' });
		}
	}
}

function* getDeviceAccessories(location) {
	try {
		let response = yield call(DeviceAPI.getDeviceAccessories, location);
		while (response.data.status !== 'completed'){
			yield delay(1000);
			response = yield call(DeviceAPI.getDeviceAccessories, location);
		}
		if(response.data.status === 'completed'){
			yield put({
				type: 'DEVICES_GET_ACCESSORIES_SUCCESS',
				payload: {
					data: response.data.result,
				},
			})
		}
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_GET_ACCESSORIES_ERROR' });
		}
	}
}

function* getDeviceAccessoriesTask(action) {
	try {
		let response = yield call(DeviceAPI.getDeviceAccessoriesTask, action.payload);
		yield getDeviceAccessories(_.get(response, 'data.location', {}));
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_GET_ACCESSORIES_TASK_ERROR' });
		}
	}
}

function* changeNameDeviceAccessory(action) {
	try {
		yield call(DeviceAPI.patchDeviceAccessory, action.payload);
		yield put({
			type: 'DEVICES_CHANGE_NAME_ACCESSORIES_SUCCESS',
			payload: action.payload
		});
		yield put({
			type: 'CHANGE_VAR_VALUE',
			payload: {
				name: 'updateCurrentAccessory',
				value: true
			},
		});
		yield put({
			type: types.NOTIFY_SUCCESS,
			payload: {
				data: 'Save success',
			}
		});
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_CHANGE_NAME_ACCESSORIES_ERROR' });
			yield put({
				type: types.NOTIFY_ERROR,
				payload: {
					data: 'Save error',
				}
			});
		}
	}
}

function* changeStatusDeviceAccessory(action) {
	try {
	    if(action.payload.type === 'attach'){
			yield call(DeviceAPI.attachStatusDeviceAccessory, action.payload);
			yield put({
				type: 'DEVICES_ATTACH_ACCESSORIES_SUCCESS',
				payload: action.payload.accessory
			});
		}else{
			yield call(DeviceAPI.detachStatusDeviceAccessory, action.payload);
			yield put({
				type: 'DEVICES_DETACH_ACCESSORIES_SUCCESS',
				payload: action.payload.accessory
			});
		}

		yield put({
			type: types.NOTIFY_SUCCESS,
			payload: {
				data: action.payload.type === 'attach'
					? 'accessories_notify_attach_success'
					: 'accessories_notify_detach_success',
			}
		});


	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_CHANGE_STATUS_ACCESSORIES_ERROR' });
			yield put({
				type: types.NOTIFY_ERROR,
				payload: {
					data: action.payload.type === 'attach'
						? 'accessories_notify_attach_error'
						: 'accessories_notify_detach_error',
				}
			});
		}
	}
}

function* getStatAccessories(action) {
    try {
        const response = yield call(DeviceAPI.getDevicesStatsAccessories, action.payload);
        yield put({
            type: 'DEVICES_STAT_ACCESSORIES_FETCH_SUCCESS',
            payload: {
                data: _.get(response, 'data', {}),
            },
        });

    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_STAT_ACCESSORIES_FETCH_ERROR' });
        }
    }
}

function* postDevicesKeysData(action) {
	try {
		yield call(DeviceAPI.postDevicesKeysData, action.payload);
		yield put({ type: 'DEVICES_SEND_KEYS_SUCCESS' });
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_SEND_KEYS_ERROR' });
		}
	}
}

function* postDevicesClipboardData(action) {
	try {
		yield call(DeviceAPI.postDevicesClipboardData, action.payload);
		yield put({ type: 'DEVICES_SEND_CLIPBOARD_SUCCESS' });
        yield put({
            type: 'CHANGE_VAR_VALUE',
            payload: {
                name: 'clipBoardSendSuccess',
                value: true
            },
        });
		yield put({
			type: types.NOTIFY_SUCCESS,
			payload: {
				data: 'Send Success to clipboard',
			}
		});
	} catch (error) {
		const errorAction = ServerRequest.errorHandler(error);

		if (errorAction) {
			yield put(errorAction);
		} else {
			yield put({ type: 'DEVICES_SEND_CLIPBOARD_ERROR' });
		}
		yield put({
			type: types.NOTIFY_ERROR,
			payload: {
				data: 'Sending failed to clipboard',
			}
		});
	}
}

function* getDevicesClipboardDataTask(action) {
    try {
        const response = yield call(DeviceAPI.getDevicesClipboardDataTask, action.payload);
        yield getDevicesClipboardData(_.get(response, 'data.location', {}));
    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_GET_CLIPBOARD_ERROR' });
        }
    }
}

function* getDevicesClipboardData(location) {
    try {
        let response = yield call(DeviceAPI.getDevicesClipboardData, location);
        while (response.data.status !== 'completed'){
            yield delay(100);
            response = yield call(DeviceAPI.getDevicesClipboardData, location);
        }
        if(response.data.status === 'completed'){
			yield put({ type: 'DEVICES_GET_CLIPBOARD_SUCCESS'});
			yield put({
				type: 'CHANGE_VAR_VALUE',
				payload: {
					name: 'clipboard',
					value: response.data.result.text,
				},
			});
            yield put({
                type: types.NOTIFY_SUCCESS,
                payload: {
                    data: 'Get Success to clipboard',
                }
            });
        }

    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'DEVICES_GET_CLIPBOARD_ERROR' });
        }
    }
}
