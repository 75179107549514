import React, {useState, useEffect} from 'react';
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {connect} from "react-redux";
import {useDispatch} from 'react-redux';
import {ValidatorForm} from "react-material-ui-form-validator";
import {withStyles} from '@material-ui/core/styles';

import {
    Checkbox,
    FormLabel,
    FormGroup,
    FormControlLabel,
    TextField,
    Grid,
    Button,
    // Select,
    // MenuItem
} from "@material-ui/core";

import {
    getUserSettingNotifications,
    postUserSettingNotifications,
} from '../../../Actions/user/userActions';

// import { timeZones } from '../../../utils/timezone'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        textAlign: 'center',
    },
    labelTop: {
        marginBottom: 30
    },
    labelEmail: {
        marginBottom: 10
    },
    buttonAction: {
        marginTop: 30
    },
    textField: {
        marginTop: 0
    },
    label: {
        marginBottom: 30,
        marginTop: 30,
        width: '100%'
    }
});

const UserNotification = (props) => {

    const dispatch = useDispatch();

    const {classes, t, notifications} = props;

    const [settings, setSettings] = useState(notifications);

    const handleChangeCheckBox = name => event => {
        setSettings({...settings, [name]: event.target.checked});
    };

    const handleChangeDaysCheckBox = number => event => {
        const {days} = settings;
        days[number] = event.target.checked;
        setSettings({
            ...settings,
            days
        });
    };

    const handleChangeInput = event => {
        setSettings({...settings, [event.target.name]: event.target.value});
    };

    useEffect(() => {
        dispatch(getUserSettingNotifications());
    }, []);

    useEffect(() => {
        setSettings({
            all_accessory_actions: !!notifications.all_accessory_actions,
            accessory_disconnect: !!notifications.accessory_disconnect,
            windows_online: !!notifications.windows_online,
            windows_offline: !!notifications.windows_offline,
            start: notifications.start ? notifications.start : '',
            end: notifications.end ? notifications.end : '',
            alternative_email: notifications.alternative_email,
            days: notifications.days,
            timezone: notifications.timezone ? notifications.timezone : 0
        });
    }, [notifications]);

    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(postUserSettingNotifications(settings));
    };

    return (
        <div className="user-settings">
            {settings &&
            <ValidatorForm
                onSubmit={handleSubmit}
            >
                <Grid container className={classes.grid}>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormLabel component="legend" className={classes.labelTop}>
                                {t('Notification Settings')}
                            </FormLabel>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={settings.all_accessory_actions}
                                        value='all_accessory_actions'
                                        onChange={handleChangeCheckBox('all_accessory_actions')}
                                    />
                                }
                                label={t('Notify about unknown devices')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={settings.accessory_disconnect}
                                        value={settings.accessory_disconnect}
                                        onChange={handleChangeCheckBox('accessory_disconnect')}
                                    />
                                }
                                label={t('Notify about disabling the standard')}
                            />
                            {/*<FormControlLabel
                                control={
                                    <Checkbox
                                        checked={settings.windows_online}
                                        value={settings.windows_online}
                                        onChange={handleChangeCheckBox('windows_online')}
                                    />
                                }
                                label={t('Notify when windows online')}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={settings.windows_offline}
                                        value={settings.windows_offline}
                                        onChange={handleChangeCheckBox('windows_offline')}
                                    />
                                }
                                label={t('Notify when windows offline')}
                            />*/}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormLabel component="legend" className={classes.labelTop}>
                                {t('Time to send notifications')}
                            </FormLabel>
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField
                                        id="time"
                                        label={t('From')}
                                        type="time"
                                        name="start"
                                        value={settings.start}
                                        onChange={handleChangeInput}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="time"
                                        label={t('To')}
                                        type="time"
                                        name="end"
                                        value={settings.end}
                                        onChange={handleChangeInput}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                       {/* <FormGroup row>
                            <FormLabel component="legend" className={classes.label}>
                                {t('Timezone')}
                            </FormLabel>
                            <Select
                                value={settings.timezone}
                                onChange={handleChangeInput}
                                name='timezone'
                                inputProps={{
                                    name: 'timezone',
                                }}
                            >
                            {timeZones.map(timeZone => (
                                <MenuItem value={timeZone.offset} key={timeZone.text}>
                                    {timeZone.text}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormGroup>*/}
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup row>
                            <FormLabel component="legend" className={classes.label}>
                                {t('Days of the week to send notifications')}
                            </FormLabel>
                            {[
                                'Monday',
                                'Tuesday',
                                'Wednesday',
                                'Thursday',
                                'Friday',
                                'Saturday',
                                'Sunday',
                            ].map((day, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={settings.days[index]}
                                            onChange={handleChangeDaysCheckBox(index)}
                                            value={settings.days[index]}
                                        />
                                    }
                                    label={t(day)}
                                />
                            ))}
                        </FormGroup>

                        <FormGroup className={classes.label}>
                            <FormLabel component="legend" className={classes.labelEmail}>
                                {t('Alternate notification mailbox')}
                            </FormLabel>
                            <TextField
                                label={t('Email')}
                                name="alternative_email"
                                className={classes.textField}
                                value={settings.alternative_email}
                                onChange={handleChangeInput}
                                margin="normal"
                            />
                        </FormGroup>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttonAction}
                            type="submit"
                        >
                            {t('Change')}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    notifications: state.userData.user.settings.notifications,
});

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(UserNotification);
