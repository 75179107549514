import {call, put, all, takeLatest} from 'redux-saga/effects';
import OrganizationsAPI from '../../services/rest-api/OrganizationsAPI';
import * as _ from 'lodash';
import ServerRequest from "../../services/ServerRequest";
import {types} from '../types';

export function* organizationSaga() {
    yield all([organizationDataFlow()]);
}

function* organizationDataFlow() {
    yield takeLatest('ORGANIZATION_CREATE', createOrganization);
    yield takeLatest('CLEAR_FIELDS', clearFields);
    yield takeLatest('ORGANIZATION_GET_LIST', getOrganizationList);
    yield takeLatest('ORGANIZATION_SECTION_DELETE', deleteOrganizationSection);
    yield takeLatest('ORGANIZATION_SECTION_RENAME', renameOrganizationSection);
}

function* clearFields() {
    yield put({
        type: 'CLEAR_FIELDS_SUCCESS',
    });
}

function* createOrganization(action) {
    try {
        const response = yield call(OrganizationsAPI.createOrganization, action.payload);

        yield put({
            type: 'ORGANIZATION_CREATE_SUCCESS',
            payload: {
                data: _.get(response, 'data.id', {}),
            },
        });

        // for create user
        //yield createUser(_.get(response, 'data.id', {}), action.payload)

    }catch (error) {
        yield put({type: 'ORGANIZATION_CREATE_ERROR'});
        yield put({
            type: types.NOTIFY_ERROR,
            payload: {
                data: 'Company not created',
            }
        });
    }
}

function* getOrganizationList(action) {
    try {
        const response = yield call(OrganizationsAPI.getOrganizationsList, action.payload);
        yield put({
            type: 'ORGANIZATION_GET_LIST_SUCCESS',
            payload: {
                data: _.get(response, 'data.items', {}),
            },
        });

    } catch (error) {
        const errorAction = ServerRequest.errorHandler(error);

        if (errorAction) {
            yield put(errorAction);
        } else {
            yield put({ type: 'ORGANIZATION_GET_LIST_ERROR' });
        }
    }
}

function* deleteOrganizationSection(action) {
    try {
        yield call(OrganizationsAPI.deleteOrganizationSection, action.payload);
        yield put({
            type: 'ORGANIZATION_SECTION_DELETE_SUCCESS',
        });
    }catch (error) {
        yield put({type: 'ORGANIZATION_SECTION_DELETE_ERROR'});
    }
}

function* renameOrganizationSection(action) {
    try {
        yield call(OrganizationsAPI.renameOrganizationSection, action.payload);
        yield put({
            type: 'ORGANIZATION_SECTION_RENAME_SUCCESS',
        });
    }catch (error) {
        console.log(error);
        yield put({type: 'ORGANIZATION_SECTION_RENAME_ERROR'});
    }
}

