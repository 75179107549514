import React, {Component} from 'react';
import './control.scss';
import MenuAppBar from '../../Components/header'
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspaceRounded';
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import Vnc from '../../Components/vnc';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonAction: {
        background: '#6200ee',
    },
    progress: {
        margin: theme.spacing(2),
        color: '#6200ee',
    },

});

class Control extends Component {

    componentWillMount(){
        if (!localStorage.getItem('DEVICE_ID')) {
            window.location.hash = '/';
        }
    }

    render() {
        const {classes, t} = this.props;
        return (
            <div className="control">
                <MenuAppBar/>
                <Grid container
                      spacing={6} className={classes.root}>
                    <Grid item xs={12}
                          style={{margin: '0 auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 18}}>
                        <Link to={"/"} className={'backButton'}>
                            <KeyboardBackspace className={classes.icon}/>
                            {t('Back to terminals')}
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={10} style={{
                        margin: '0 auto',
                        textAlign: 'center',
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 14
                    }}>
                        <Vnc/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withNamespaces(),
)(Control);
