import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {Language} from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import i18n from "../../i18n";
import {withNamespaces} from "react-i18next";

class SwitchLang extends Component {
    state = {
        anchorEl: null,
    };

    handleChange = event => {
        this.setState({auth: event.target.checked});
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {anchorEl} = this.state;
        const { t } = this.props;
        const open = Boolean(anchorEl);
        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
            localStorage.setItem('lng',lng);
        };
        return (
            <div>
                <IconButton
                    aria-owns={open ? 'menu-langbar' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    color="inherit"
                >
                    <Language/>
                    <span className="headerUserName">
                        {t('Language')}
                    </span>
                </IconButton>
                <Menu
                    id="menu-langbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                    <MenuItem onClick={() => changeLanguage('ru')}>Русский</MenuItem>

                </Menu>
            </div>
        );
    }
}

export default withNamespaces()(SwitchLang);
