import { all } from 'redux-saga/effects';
import {userSaga} from './user/userSaga';
import {deviceSaga} from './device/deviceSaga';
import {organizationSaga} from "./organization/organizationSaga";

export default function* rootSaga() {
	yield all([
		userSaga(),
		deviceSaga(),
		organizationSaga()
	]);
}
