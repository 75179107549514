import React, {Component} from "react";
import "./backup.scss";
import MenuAppBar from "../../Components/header";
import {
	ShutterSpeed,
	CloudUpload
} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	CircularProgress,
	IconButton,
	Avatar,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	ListItemSecondaryAction,
	Grid,
	Paper,
} from "@material-ui/core";

import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import axios from "axios";
import * as _ from "lodash";
import connect from "react-redux/es/connect/connect";
import {getDevicesSnapshotsData, makeDevicesSnapshots} from "../../Actions/device/deviceActions";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspaceRounded';
import {Link} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";

const cloud = require("../../assets/img/cloud.png");
const styles = theme => ({
	root: {
		flexGrow: 1
	},
	progress: {
		margin: theme.spacing(2),
		color: '#6200ee',
	},
	buttonAction: {
		background: "#6200ee"
	},
	textField: {
		width: '100%',
		marginBottom: 0,
		marginTop: 0
	},
	noPadding: {
		paddingTop: '0px'
	},
	container: {
		width: '100%',
	},
	buttonModal: {
		marginTop: 30
	}
});


class Backup extends Component {
	state = {
		open: false,
		snapshots: [],
		name: '',
		description: '',
		openMakeSnapshot: false,
		alertTitle: this.props.t('Loading list of snapshots'),
		noSnapshots: false
	};
	handleOpen = () => {
		this.setState({open: true}, () => {
			setTimeout(() => {
				this.setState({open: false})
			}, 2000)
		});
	};
	handleOpenMakeSnapshot = () => {
		this.setState({openMakeSnapshot: true});
	};

	handleClose = () => {
		this.setState({open: false});
	};
	handleCloseMakeSnapshot = () => {
		this.setState({openMakeSnapshot: false});
	};
	handleChange = (name) => event => {
		this.setState({
			[name]: event.target.value,
		});
	};
	componentDidMount() {
		// this.timer = setInterval(this.progress, 5000);
		this.handleOpen();
		this.props.dispatch(getDevicesSnapshotsData({id: localStorage.getItem('DEVICE_ID'), type: 'first'}))

	}

	componentDidUpdate(prevProps) {
		if (prevProps.device.snapshots !== {} && prevProps.device.snapshots !== this.props.device.snapshots) {
			if (this.props.device.snapshots.status === "running") {
				setTimeout(() => {
					this.props.dispatch(getDevicesSnapshotsData({id: localStorage.getItem('DEVICE_ID'), type: 'again'}))
				}, 2000)
			} else if (this.props.device.snapshots && this.props.device.snapshots && this.props.device.snapshots.status === 'completed') {
			this.setState({
				snapshots: _.get(this.props, 'device.snapshots.result', []),
				noSnapshots: false
			}, () => {
				setTimeout(() => {
					this.handleClose()
				}, 2000)
			})

		}
		} else if (this.props.device.snapshots && this.props.device.snapshots.length === 0) {
			// toast.error('Failing get POS information')
			this.setState({
				snapshots: _.get(this.props, 'device.snapshots.result', []),
				noSnapshots: true
			}, () => {
				setTimeout(() => {
					this.handleClose()
				}, 2000)
			})
		}
	}
	restoreSnapshot = (snaphotId) => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${localStorage.getItem('DEVICE_ID')}/snapshots/${snaphotId}/restore/`, {
		}, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					this.setState({
						alertTitle: this.props.t('Restore POS starting, it can take a few minutes'),
						open: true
					}, () => {
						this.restoreSnapshotTask(res.data.location)
					})
				}, err => {
					console.log('err', err);
				}
			)
	};

	restoreSnapshotTask = (location) => {
		axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
				"Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
			}
		})
			.then(res => {
					if (res.data.status === "running") {
						setTimeout(() => {
							this.restoreSnapshotTask(location)
						}, 3000)
					} else if (res.data.status === "completed") {
						this.setState({
							open: false
						})
					}
				}, err => {
					console.log('err', err);
				}
			)
	};

	handleSubmit = (event) => {
		event.preventDefault();

			this.props.dispatch(makeDevicesSnapshots({id: localStorage.getItem('DEVICE_ID'), data: {
					name: this.state.name,
					description: this.state.description
				}}));
			this.props.dispatch(getDevicesSnapshotsData({id: localStorage.getItem('DEVICE_ID'), type: 'first'}));
			this.setState({openMakeSnapshot: false});
			toast.info(this.props.t('Request for create snapshots'));
	};

	render() {
		const {classes, t} = this.props;
		return (
			<div className="backup">
				<MenuAppBar/>
				<Grid container spacing={6} className={classes.root}>
					<Grid item xs={12}
						  style={{margin: '0 auto', paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 18}}>
						<Link to={"/"} className={'backButton'}>
							<KeyboardBackspace/>
							{t('Back to terminals')}
						</Link>
						<h2 style={{paddingLeft: '20px', paddingTop: "20px"}}>{t('System Restore')}</h2>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid>
							 <Paper elevation={2} style={{paddingTop: 20, paddingBottom: 20}}>
								 {!this.state.noSnapshots ? <h3 style={{paddingLeft: 26}}>{t('Snapshots')}</h3>
									 : <h3 style={{paddingLeft: 26}}>No snapshots</h3>
								 }
									<List>
										{this.state.snapshots && this.state.snapshots.map((elem, index) => {
											return (
												<ListItem key={index}>
													<ListItemAvatar>
														<Avatar>
															<ShutterSpeed/>
														</Avatar>
													</ListItemAvatar>
													<ListItemText
														primary={elem.name}
														secondary={elem.description}

													/>
													<ListItemSecondaryAction
														style={{paddingRight: '12px'}}
														className="restore_button"
													>
														<Button
															variant="outlined"
															color="secondary"
															onClick={() => {
																this.restoreSnapshot(elem.uuid)
															}}
														>
															{t('Restore')}
														</Button>
														<ListItemSecondaryAction className="restore_button_mobile">
															<IconButton
																aria-label={t('Restore')}
																color="secondary"
																onClick={() => {
																	this.restoreSnapshot(elem.uuid)
																}}
															>
																<CloudUpload/>
															</IconButton>
														</ListItemSecondaryAction>
													</ListItemSecondaryAction>

												</ListItem>
											)
										})
										}
									</List>
									<div style={{paddingTop: 50, paddingRight: 20, textAlign: 'right'}}>
										<Button
											variant="contained"
											color="secondary"
											className={classes.buttonAction}
											onClick={this.handleOpenMakeSnapshot}
										>
												{t('Save new Snapshot')}
										</Button>
									</div>
								</Paper>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Paper elevation={2} style={{
							paddingTop: 46, paddingBottom: 46, textAlign: 'center', background: '#061D2E' +
								' url(' + cloud + ') no-repeat center', backgroundSize: 'cover'
						}}>
							<h3 style={{color: '#fff', fontSize: '28px'}}>{t('Cloud Backup')}</h3>
							<h4 style={{color: '#fff', fontSize: '20px'}}>{t('Save your data to our Cloud, it`s fast and safe')}</h4>
						</Paper>
					</Grid>
				</Grid>
				<Dialog
					open={this.state.open}
					onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{this.state.alertTitle}</DialogTitle>
					<DialogContent  style={{
						textAlign: 'center', margin: '20px' +
							' auto !important'
					}}>
						<DialogContentText id="alert-dialog-description" style={{
							textAlign: 'center', margin: '20px' +
								' auto !important'
						}}>
						</DialogContentText>
						<CircularProgress className={classes.progress}/>
					</DialogContent>
				</Dialog>
				<Dialog
					open={this.state.openMakeSnapshot}
					onClose={this.handleCloseMakeSnapshot}
					aria-labelledby="make-snapshot-dialog-title"
					aria-describedby="make-snapshot-dialog-description"
				>
					<DialogTitle
						id="alert-dialog-title"
						 style={{padding: '24px 35px 5px'}}
								 >
						{t("Restore system by Snapshot")}
					</DialogTitle>
					<DialogContent>
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleSubmit}
                                className={classes.container}
                            >
								<Grid container spacing={0} className={classes.noPadding}>
									<Grid item xs={12}>
										<TextValidator
											label={t('Name')}
											onChange={this.handleChange('name')}
											name="name"
											className={classes.textField}
											margin="normal"
											value={this.state.name}
											validators={['required']}
											errorMessages={[t('This field is required')]}
										/>
									</Grid>

									<Grid item xs={12}>
										<TextValidator
											label={t('Description')}
											onChange={this.handleChange('description')}
											name="description"
											className={classes.textField}
											margin="normal"
											value={this.state.description}
											validators={['required']}
											errorMessages={[t('This field is required')]}
										/>
									</Grid>

									<Grid item xs={6} style={{
										margin: '0 auto',
										padding: 0
									}}>
										<Button
											variant="contained"
											color="secondary"
											onClick={this.handleCloseMakeSnapshot}
											className={classes.buttonModal}
										>
											{t('Cancel')}
										</Button>
									</Grid>
									<Grid item xs={6} style={{
										textAlign: 'right',
										padding: 0
									}}>
										<Button
											variant="contained"
											color="secondary"
											className={classes.buttonModal}
											type="submit"
										>
											{t('Create')}
										</Button>
									</Grid>
								</Grid>
						</ValidatorForm>

					</DialogContent>
				</Dialog>
				<ToastContainer/>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	device: state.deviceData.device,
});

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
	withNamespaces(),
)(Backup);
