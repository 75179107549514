import React, {Component} from 'react';
import './create.scss';
import MenuAppBar from '../../Components/header'
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import IconButton from '@material-ui/core/IconButton';
import Paper from "@material-ui/core/Paper/Paper";
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {putOrganizations, clearFields} from '../../Actions/organization/organizationAction';
import {connect} from "react-redux";
import {CheckCircleOutline, Visibility, VisibilityOff, AutorenewOutlined} from "@material-ui/icons";
import generator from 'generate-password';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonAction: {
        background: '#6200ee',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    center: {
        textAlign: 'center',
    },
    alignBottom: {
        alignSelf: 'flex-end',
    },
    noPadding: {
        paddingTop: '0px'
    }
});


class Create extends Component {


    constructor(props) {
        super(props);

        this.clearFields();

        this._password = this.generatePassword();

        this.state = {
            expanded: null,
            completed: 40,
            completedMem: 90,
            buffer: 10,
            showPassword: true,
            organization: {
                statusOrganization: '',
            },
            companyName: '',
            email: '',
            password: this._password,
            confirmPassword: this._password,
        };
    }

    generatePassword = () => {
        return generator.generate({
            length: 10,
            number: true,
        })
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => value === this.state.password);
    }

    componentDidUpdate(prevProps, prevState){
        if(
            prevProps.organization
            && prevProps.organization.statusOrganization === 'Success'
            && prevProps.organization.statusOrganization !== this.props.organization.statusOrganization
        ){
            const password = this.generatePassword();
            this.setState({
                companyName: '',
                email: '',
                password: password,
                confirmPassword: password,
            })
        }
    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    clearFields = () => {
        this.props.dispatch(clearFields());
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.dispatch(putOrganizations({
            "name": this.state.companyName,
            "email": this.state.email,
            "password": this.state.password,
            "role": "org-admin"
        }));
    };

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    handleClickGeneratedPassword = () => {
        const password = this.generatePassword();
        this.setState({
            password: password,
            confirmPassword: password,
        });
    };

    render() {
        const {classes, t} = this.props;
        return (
            <div className="create">
                <MenuAppBar/>
                <div className="loginWrapper">
                    <h2>{t('Create Organization')}</h2>
                    <Paper elevation={2} style={{paddingTop: 26, paddingBottom: 26}}>
                        <Grid container
                              spacing={6} className={classes.root}>
                            <Grid item xs={10} style={{
                                margin: '0 auto',
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: 14
                            }}>
                                {this.props.organization && this.props.organization.statusOrganization === 'Success' ?
                                    <div className={classes.center}>
                                        <p>
                                            <CheckCircleOutline style={{fontSize: 120}}/>
                                        </p>
                                        <h2 className={classes.center}>{t('Company created')}</h2>
                                        <p><strong>{t('Company name')}: </strong> {this.state.companyName}</p>
                                        <div>
                                            <h2 className={classes.center}>{t('User created')}</h2>
                                            <p><strong>{t('Email')}: </strong> {this.state.email}</p>
                                            <Button variant="contained" color="secondary"
                                                    className={classes.buttonAction} onClick={this.clearFields}>
                                                {t('Add new organization')}
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    <ValidatorForm
                                        ref="form"
                                        onSubmit={this.handleSubmit}
                                        className={classes.container}
                                    >

                                        <Grid container spacing={0} className={classes.noPadding}>
                                            <Grid item xs={12}>
                                                <TextValidator
                                                    label={t('Company name')}
                                                    onChange={this.handleChange('companyName')}
                                                    name="companyName"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    value={this.state.companyName}
                                                    validators={['required']}
                                                    errorMessages={[t('This field is required')]}
                                                />
                                                <TextValidator
                                                    label={t('Email')}
                                                    onChange={this.handleChange('email')}
                                                    name="email"
                                                    className={classes.textField}
                                                    margin="normal"
                                                    value={this.state.email}
                                                    validators={['required', 'isEmail']}
                                                    errorMessages={[t('This field is required'), t('Field is not valid')]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0} className={classes.noPadding}>
                                            <Grid item xs={8}>
                                                <TextValidator
                                                    label={t('Password')}
                                                    onChange={this.handleChange('password')}
                                                    name="password"
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    value={this.state.password}
                                                    validators={['required']}
                                                    errorMessages={[t('This field is required')]}

                                                />
                                            </Grid>

                                            <Grid item xs={2} className={classes.alignBottom}>
                                                <IconButton
                                                    aria-label="Generated password"
                                                    onClick={this.handleClickGeneratedPassword}
                                                >
                                                    <AutorenewOutlined/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={2} className={classes.alignBottom}>
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={0} className={classes.noPadding}>
                                            <Grid item xs={12}>
                                                <TextValidator
                                                    label={t('Confirm Password')}
                                                    onChange={this.handleChange('confirmPassword')}
                                                    name="confirm-password"
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    className={classes.textField}
                                                    margin="normal"
                                                    value={this.state.confirmPassword}
                                                    validators={['isPasswordMatch', 'required']}
                                                    errorMessages={[t("Passwords don't match"), t('This field is required')]}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={0}>
                                            <Grid item xs={6} style={{
                                                margin: '0 auto',
                                                padding: 0
                                            }}>

                                            </Grid>
                                            <Grid item xs={6} style={{
                                                textAlign: 'right',
                                                padding: 0
                                            }}>
                                                <Button variant="contained" color="secondary"
                                                        className={classes.buttonAction} type="submit">
                                                    {t('Create')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    organization: state.organizationsData.organization,
});

export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(Create);
