import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

class Notify extends Component {

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.notification
            && prevProps.notification !== this.props.notification
        ) {
            const {message,type} = this.props.notification;
            this.notify(this.props.t(message),type);
        }
    }

    notify = (message,type) => toast(message,{
        type: type,
    });

    render() {
        return (
            <ToastContainer />
        );
    }
}

const mapStateToProps = state => ({
    notification: state.otherData.notification,
});

export default compose(
    connect(mapStateToProps),
    withNamespaces(),
)(Notify);
