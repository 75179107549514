const initialState = {
    device: {
        tree: {}
    }
};

const deviceData = (state = initialState, action) => {
    switch (action.type) {
        case 'DEVICES_TREE_FETCH_SUCCESS':
            return {
                ...state,
                device: {
                    ...state.device,
                    tree: action.payload.data
                }
            };
        case 'DEVICES_TREE_FETCH_ERROR':
            return {
                ...state,
                device: {
                    ...state.device,
                    tree: action.payload
                }
            };
        case 'DEVICE_STAT_FETCH_SUCCESS':
            return {
                ...state,
                device: {
                    ...state.device,
                    stat: action.payload.data
                }
            };
        case 'DEVICE_STAT_FETCH_ERROR':
            return {
                ...state,
                currentDevice: 0,
                currentDeviceName: '',
                device: {
                    ...state.device,
                    stat: action.payload
                }
            };
        case 'DEVICE_REMOTE_DATA_SUCCESS':
            return {
                ...state,
                device: {
                    ...state.device,
                    remote: action.payload.data,
                }
            };
        case 'DEVICES_CLEAR_DATA':
            return {
                ...state,
                device: {
                    ...state.device,
                    remote: null
                }
            };
        case 'DEVICE_SNAPSHOTS_FETCH_SUCCESS':
            return {
                ...state,
                device: {
                    ...state.device,
                    snapshots: action.payload.data
                }
            };
        case 'DEVICE_SNAPSHOTS_FETCH_ERROR':
            return {
                ...state,
                device: {
                    ...state.device,
                    snapshots: {
                        ...state.snapshots,
                        lacation: action.payload
                    }
                }
            };
        case 'DEVICE_MAKE_SNAPSHOTS_SUCCESS':
            return {
                ...state,
                device: {
                    ...state.device,
                    snapshots: {
                        ...state.snapshots,
                        snapshots: action.payload
                    }
                }
            };
        case 'DEVICE_MAKE_SNAPSHOTS_ERROR':
            return {
                ...state,
                device: {
                    ...state.device,
                    snapshots: {
                        ...state.snapshots,
                        snapshots: action.payload
                    }
                }
            };
        case 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST_SUCCESS':
            return {
                ...state,
                listUnmountedPos: action.payload.data

            };
        case 'DEVICES_NOT_IN_USE_GET_LIST_SUCCESS':
            return {
                ...state,
                listNotInUsePos: action.payload.data

            };
        case 'DEVICES_WITHOUT_ORGANIZATION_GET_LIST_ERROR':
            return {
                ...state,
            };
        case 'DEVICES_STAT_ARCHIVE_FETCH_SUCCESS':
            return {
                ...state,
                stats: action.payload.data

            };
        case 'DEVICES_STAT_ACCESSORIES_FETCH_SUCCESS':
            return {
                ...state,
                statsAccessories: action.payload.data

            };
        case 'DEVICES_GET_ACCESSORIES_SUCCESS':
            return {
                ...state,
                accessories: action.payload.data

            };
        case 'DEVICES_ATTACH_ACCESSORIES_SUCCESS':
            const itemAttach = (state.accessories).find(item => item.uuid === action.payload);
            return {
                ...state,
                accessories: [
                    ...(state.accessories).filter(item => item.uuid !== action.payload),
                    {
                        ...itemAttach,
                        status: 'online'
                    }
                ]
            };
        case 'DEVICES_DETACH_ACCESSORIES_SUCCESS':
            const itemDetach = (state.accessories).find(item => item.uuid === action.payload);
            return {
                ...state,
                accessories: [
                    ...(state.accessories).filter(item => item.uuid !== action.payload),
                    {
                        ...itemDetach,
                        status: 'unknown'
                    }
                ]
            };
        case 'DEVICES_CHANGE_NAME_ACCESSORIES_SUCCESS':
            const itemRenamed = (state.accessories).find(item => item.uuid === action.payload.uuid);
            return {
                ...state,
                accessories: [
                    ...(state.accessories).filter(item => item.uuid !== action.payload.uuid),
                    {
                        ...itemRenamed,
                        name: action.payload.name
                    }
                ]
            };
        default:
            return state
    }
};

export default deviceData
