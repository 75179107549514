import React, {Component} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {AccountCircle} from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {withNamespaces} from "react-i18next";

class UserMenu extends Component {

    state = {
        auth: !!localStorage.getItem('email'),
        anchorEl: null,
        email: localStorage.getItem('email')
    };

    handleChange = event => {
        this.setState({auth: event.target.checked});
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleLogOut = () => {
        this.setState({
            auth: false,
            anchorEl: null,
            email: null,
        });
        localStorage.clear();
	    window.location.hash = '/login'
    };

    goTo = (link) => {
        window.location.hash = '/' + link
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {anchorEl, email, auth} = this.state;
        const {t} = this.props;
        const open = Boolean(anchorEl);
        return (
            <div>
                {!auth && (
                    <IconButton
                        aria-owns={open ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.goTo('login')}
                        color="inherit"
                    >
                        <AccountCircle/>
                        <span className="headerUserName">
                            {t('Sign in')}
                        </span>
                    </IconButton>
                )}
                {auth && (
                <div>
                    <IconButton
                        aria-owns={open ? 'menu-langbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                    >
                        <AccountCircle/>
                        <span className="headerUserName">
                            {email}
                        </span>
                    </IconButton>

                    <Menu
                        id="menu-langbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={this.handleClose}
                    >
                        <MenuItem onClick={() => this.goTo('settings')}>{t('Settings')}</MenuItem>
                        <MenuItem onClick={this.handleLogOut}>{t('Log out')}</MenuItem>
                    </Menu>
                </div>
                )}
            </div>
        );
    }
}

export default withNamespaces()(UserMenu);
