import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import SwitchLang from '../switch-lang';
import UserMenu from '../user/user-menu';
import {PersonAdd} from "@material-ui/icons";
import {withNamespaces} from "react-i18next";
import {compose} from "redux";
import {
	Grid,
	IconButton,
	Typography,
	Toolbar,
	AppBar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import './style.scss';
import Notify from "../notify";
import {withRouter} from 'react-router-dom';
const logo = require('../../assets/img/logo.jpg');

const styles = {
	root: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
	},
	grid: {
		paddingTop: 0,
	}
};

class MenuAppBar extends Component {

	state = {
		auth: !!localStorage.getItem('email'),
	};

	componentDidMount() {
		if (
			!localStorage.getItem('USER_JWT')
			&& !localStorage.getItem('role')
			&& !localStorage.getItem('email')
			&& this.props.pathname !== '/login') {
				this.props.history.push('/login');
		}
	}

	goCreateUser = () => window.location.hash = '/create';

	render() {
		const { classes,t } = this.props;
		const { auth } = this.state;
		return (
			<div className={classes.root}>
				<AppBar position="static" className="header">
					<Grid container spacing={0} className={classes.grid}>
						<Grid item xs={12} md={3}>
							<Toolbar className="header__toolbar">
								<Typography variant="h6" color="inherit" className="header__logo">
									<Link to="/" className={"logo"}>
										<img src={logo} alt=""/>
									</Link>
								</Typography>
							</Toolbar>
						</Grid>
						<Grid item xs={12} md={9}>
							<Toolbar className="header__toolbar">
							{(auth && localStorage.getItem('role') === 'super-admin') && (
								<IconButton
									onClick={this.goCreateUser}
									color="inherit"
								>
									<PersonAdd/>
									<span className="headerUserName">
									{t('Create Organization')}
								</span>
								</IconButton>
							)}
							<SwitchLang/>
							<UserMenu/>
							</Toolbar>
						</Grid>
					</Grid>
				</AppBar>
				<Notify/>
			</div>
		);
	}
}

MenuAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withNamespaces(),
	withRouter,
)(MenuAppBar);
