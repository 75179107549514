import React from "react";
import { DesktopMac } from "@material-ui/icons";
import {
    ListItemSecondaryAction,
    Paper,
    ListItemText,
    Avatar,
    ListItemAvatar,
    ListItem,
    List,
    Button
} from "@material-ui/core";
import "./index.scss";

const DevicesList = ({items, showSerial, actions}) => {
    return (
        <Paper elevation={2} className="devices-list" style={{paddingTop: 20, paddingBottom: 20}}>
            <List className="devices-list__list">
                {items.map((elem, index) => {
                    return (
                        <ListItem key={index} className="devices-list__list__item">
                            <ListItemAvatar>
                                <Avatar>
                                    <DesktopMac/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                className="devices-list__list__item__text"
                                primary={elem.name}
                                secondary={showSerial && elem.serial}
                            />
                            {actions &&
                            <ListItemSecondaryAction
                                style={{paddingRight: '12px', visibility: 'visible' }}
                                className="devices-list__list__item__buttons"
                            >
                                {actions.map((item,index) =>
                                     <Button
                                         key={index}
                                         variant="outlined"
                                         color="secondary"
                                         style={{
                                             background: '#6200ee',
                                             padding: '5px 10px',
                                             fontSize: "10px",
                                             marginRight: 10
                                         }}
                                         onClick={() => item.buttonFunc(
                                             elem,
                                             item.title,
                                             typeof item.confirmText !== 'function' ? item.confirmText : item.confirmText(elem),
                                             item.type
                                         )}
                                     >
                                         {typeof item.title !== 'function' ? item.title : item.title(elem)}
                                     </Button>
                                )}
                            </ListItemSecondaryAction>
                            }
                        </ListItem>
                    )
                })}
            </List>
        </Paper>
    )
};

export default DevicesList;
