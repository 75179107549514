import React from 'react';

import {
    CircularProgress,
    Grid,
    LinearProgress,
    Paper,
    withStyles,
} from "@material-ui/core";

import {Area, AreaChart, ResponsiveContainer, Tooltip} from "recharts";
import {compose} from "redux";
import {withNamespaces} from "react-i18next";
import * as _ from "lodash";

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
        color: '#6200ee',
    },
    linearColorPrimary: {
        backgroundColor: '#e5e5e5',
    },
    linearBarColorPrimary: {
        backgroundColor: '#6200ee',
    },
    grid: {
        paddingTop: 0
    },
    block: {
        padding: '2px 10px 16px 10px',
        position: 'relative',
        alignItems: 'center',
        display: "flex",
    }
});


class DashboardStats extends React.Component {

    state = {
        cpuLoad: [],
        completed: 0,
        completedMem: 0,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {statFull} = this.props;
        if (
            statFull
            && statFull !== prevProps.statFull
        ) {
            let cpuLoad;
            if (this.state.cpuLoad.length < 2) {
                cpuLoad = _.get(statFull, 'cpu.load', []).concat(_.get(statFull, 'cpu.load', []));
            }else{
                cpuLoad = this.state.cpuLoad.concat(_.get(statFull, 'cpu.load', []));
            }

            this.setState({
                cpuLoad,
            })
        }
    }

    render() {
        let {statFull, t, classes, deviceStatLoading} = this.props;
        const {cpuLoad} = this.state;
        const completed = _.get(statFull, 'hdd.percent', {});
        const completedMem = _.get(statFull, 'memory.percent', {});

        return (
            <Grid container
                  spacing={4}
                  alignItems="flex-start"
                  justify="space-between"
                  style={{paddingTop: 0}}>
                <Grid item xs={12} sm={6}
                      style={{paddingBottom: "10px"}}>
                    <Paper elevation={2} className={classes.block}>
                        {deviceStatLoading ?
                            <CircularProgress className={classes.progress}/>
                            : <Grid container className={classes.grid}>
                                {statFull && statFull.hdd ?
                                <>
                                    <Grid item xs={6}>
                                        <h3>HDD: {statFull.hdd.total ? `${Math.round(statFull.hdd.total * 10 / 1024) / 10} GB` : t('No data')}</h3>
                                        <span className="subTitle">
                                            {t('Available')}: {statFull.hdd.free ? `${Math.round(statFull.hdd.free * 10 / 1024) / 10} GB` : t('No data')}
                                        </span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div
                                            className={statFull.hdd_status ? "subTitle-hdd-health" +
                                                " subTitle-hdd-health--" + statFull.hdd_status.status : 'subTitle-hdd-health subTitle-hdd-health--GOOD'}>
                                            {statFull.hdd_status && t(statFull.hdd_status.status)}
                                        </div>
                                        <div className="subTitle subTitle__right">
                                            {t('Reserved')}: {statFull.hdd.reserved ? `${Math.round(statFull.hdd.reserved * 10 / 1024) / 10} GB` : t('No data')}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LinearProgress
                                            classes={{
                                                colorPrimary: classes.linearColorPrimary,
                                                barColorPrimary: classes.linearBarColorPrimary,
                                            }}
                                            style={{marginTop: 14}}
                                            variant="determinate"
                                            value={completed}/>
                                    </Grid>
                                </>
                                :
                                <Grid item xs={12} style={{alignItems: 'center'}}>
                                    <div className="custom alert alert-error">
                                        {t('Connection Error')}
                                    </div>
                                </Grid>
                                }
                            </Grid>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}
                      style={{paddingBottom: "10px"}}>
                    <Paper elevation={2} className={classes.block}>
                        {deviceStatLoading ?
                            <CircularProgress className={classes.progress}/>
                            : <Grid container className={classes.grid}>
                                {statFull && statFull.memory ?
                                <div className="cpu-card">
                                    <h3>
                                        {t('Memory')}: {statFull.memory.total ? `${Math.round(statFull.memory.total * 10 / 1024) / 10} GB` : t('No data')}
                                    </h3>
                                        <span className="subTitle">
                                            {t('Available')}: {
                                                statFull.memory.available ?
                                                    (statFull.memory.available > 1024 ?
                                                        Math.round(statFull.memory.available * 10 / 1024) / 10 + ' GB'
                                                        :
                                                        statFull.memory.available + ' MB')
                                                    :
                                                    t('No data')
                                            }
                                        </span>
                                    <LinearProgress
                                        color="secondary"
                                        style={{marginTop: 14}}
                                        variant="determinate"
                                        value={completedMem}
                                    />
                                </div>
                                :
                                <Grid item xs={12} style={{alignItems: 'center'}}>
                                    <div className="custom alert alert-error">
                                        {t('Connection Error')}
                                    </div>
                                </Grid>
                                }
                            </Grid>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}
                      style={{paddingBottom: "10px"}}>
                    <Paper elevation={2} className={classes.block}>
                        {deviceStatLoading ?
                            <CircularProgress className={classes.progress}/>
                            : <Grid container className={classes.grid}>
                                {statFull && statFull.cpu ?
                                    <>
                                        <h3>CPU: {statFull && statFull.cpu && Math.floor(statFull.cpu.freq * 10 / 1000) / 10} GHz</h3>
                                        <span
                                            className="subTitle-Temp">{statFull && statFull.cpu && statFull.cpu.temperature}ºC</span>
                                        <div className="chart-container">
                                            <ResponsiveContainer>
                                                <AreaChart data={cpuLoad}
                                                           margin={{
                                                               top: 10,
                                                               right: 0,
                                                               left: 0,
                                                               bottom: 0
                                                           }}>
                                                    <Tooltip/>
                                                    <defs>
                                                        <linearGradient id="colorCpu" x1="0" y1="0"
                                                                        x2="1" y2="0">
                                                            <stop offset="5%" stopColor="#9EE77F"
                                                                  stopOpacity={0.6}/>
                                                            <stop offset="95%" stopColor="#2DEFDB"
                                                                  stopOpacity={0.8}/>
                                                        </linearGradient>
                                                    </defs>
                                                    <Area type='monotone' dataKey='percent'
                                                          stroke='#2DEFDB'
                                                          fill='url(#colorCpu)'/>
                                                </AreaChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </>
                                    :
                                    <Grid item xs={12} style={{alignItems: 'center'}}>
                                        <div className="custom alert alert-error">
                                            {t('Connection Error')}
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}
                      style={{paddingBottom: "10px"}}>

                    <Paper elevation={2} className={classes.block}>
                        {deviceStatLoading ?
                            <CircularProgress className={classes.progress}/>
                            :
                            <div>
                                {statFull && statFull.system_available ?
                                    <span className="windows-status">
                                        {t('Windows working')}
                                    </span>
                                    :
                                    <span className="windows-status bad">
                                        {t('Windows not working')}
                                    </span>
                                }
                            </div>
                        }
                    </Paper>

                </Grid>
            </Grid>
        )
    }

}

const composeComponent = compose(
    withNamespaces(),
    withStyles(styles),
)(DashboardStats);

export default composeComponent;
