import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from "react-redux";
import {withNamespaces} from 'react-i18next';

import {getUserData, clearUserData} from '../../Actions/user/userActions'

import {withStyles} from '@material-ui/core/styles';

import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import {
    Grid,
    Button,
    Paper,
    IconButton
} from '@material-ui/core';

import {
    Visibility, VisibilityOff
} from "@material-ui/icons";

import MenuAppBar from '../../Components/header'

import './login.scss';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonAction: {
        background: '#6200ee',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
        marginTop: 0
    },
    alignBottom: {
        alignSelf: 'flex-end',
    }
});


class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            showPassword: false,
        };
    }

    componentDidUpdate (prevProps) {
        if (
            this.props.user
            && this.props.user.loginStatus
            && this.props.user.loginStatus === 'Error'
            && this.props.user.loginStatus !== prevProps.user.loginStatus
        ) {
            this.props.dispatch(clearUserData());
        }
    }

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    handleSubmit = (event) => {
        event.preventDefault();
	    this.props.dispatch(getUserData({
		    "email": this.state.email,
		    "password": this.state.password
	    }));
    };

    render() {
        const {classes, t} = this.props;
        return (
            <div className="login">
                <MenuAppBar/>
                <div className="loginWrapper">
                    <h2>{t('Authorization')}</h2>
                    <Paper elevation={2} style={{paddingTop: 26, paddingBottom: 26}}>
                        <Grid container
                              spacing={6} className={classes.root}>
                            <Grid item xs={10} style={{
                                margin: '0 auto',
                                paddingTop: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingBottom: 14
                            }}>
                                <ValidatorForm
                                    ref="form"
                                    onSubmit={this.handleSubmit}
                                    className={classes.container}
                                >
                                    <Grid container spacing={0} className={classes.root}>
                                        <Grid item xs={12}>
                                            <TextValidator
                                               label={t('Email')}
                                               onChange={this.handleChange('email')}
                                               name="email"
                                               className={classes.textField}
                                               margin="normal"
                                               value={this.state.email}
                                               validators={['required', 'isEmail']}
                                               errorMessages={[t('This field is required'), t('Field is not valid')]}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <TextValidator
                                               label={t('Password')}
                                               onChange={this.handleChange('password')}
                                               autoComplete="current-password"
                                               name="password"
                                               type={this.state.showPassword ? 'text' : 'password'}
                                               className={classes.textField}
                                               margin="normal"
                                               value={this.state.password}
                                               validators={['required']}
                                               errorMessages={[t('This field is required')]}
                                            />
                                        </Grid>
                                        <Grid item xs={2} className={classes.alignBottom}>
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </Grid>
                                   </Grid>
                                   <Grid container spacing={0} className={classes.root}>
                                        <Grid item xs={12} style={{
                                            textAlign: 'right',
                                            padding: 0
                                        }}>
                                            <Button variant="contained" color="secondary"
                                                    className={classes.buttonAction} type="submit">
                                                {t('Log in')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
	user: state.userData.user,
});
export default compose(
	connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(Login);
