import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom'

import * as _ from "lodash";
import {withNamespaces} from 'react-i18next';
import axios from "axios";
import {compose} from 'redux';
import {connect} from "react-redux";

import SortableTree, {
    getTreeFromFlatData,
    addNodeUnderParent,
    changeNodeAtPath,
} from 'react-sortable-tree';

import {
    getDevicesTreeData,
    getDevicesStatData,
    makeDevicesActivate,
    makeDevicesUnLink,
    clearDevicesData
} from "../../Actions/device/deviceActions";

import {
    getOrganizations,
    deleteSectionOrganizations,
    renameSectionOrganizations,
    dontUpdateTreeOrganizations
} from "../../Actions/organization/organizationAction";

// material ui core
import {withStyles} from '@material-ui/core/styles';
import {
    Input,
    InputLabel,
    FormControl,
    Button,
    Paper,
    Grid,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    CircularProgress,
    IconButton,
    InputBase,
    Switch,
    Menu,
    MenuItem,
    Tooltip as TooltipTap
} from '@material-ui/core';

// material ui icons
import {
    Delete as DeleteIcon,
    Cancel as CancelIcon,
    Edit as EditIcon,
    Save as SaveIcon,
    Visibility as VisibilityIcon,
    CreateNewFolder,
    Search as SearchIcon,
    Help as HelpIcon,
    MoreVert as MoreVertIcon
} from "@material-ui/icons";

import SelectSearch from 'react-select-search';
import DashboardBottom from '../../Components/dashboard/dashboardBottom';
import Accessories from '../../Components/accessories';
import MenuAppBar from '../../Components/header'
import DashboardStats from '../../Components/dashboard/dashboardStats';

import 'react-sortable-tree/style.css';
import './dashboard.scss';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonAction: {
        background: '#6200ee',
        padding: '5px 10px',
        fontSize: "10px"
    },
    buttonLink: {
        fontSize: "12px"
    },
    progress: {
        margin: theme.spacing(2),
        color: '#6200ee',
    },
    progressBig: {
        margin: '140px auto',
        display: 'block',
        color: '#6200ee',
    },
    searchSection: {
        marginBottom: "20px",
    },
    searchGrid: {
        paddingTop: "3px",
        paddingBottom: "3px",
    },
    searchInput: {
        width: "100%",
        marginTop: "10px"
    },
    link: {
        textDecoration: 'none',
    },
});


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
            newParent: null,
            changedSection: 0,
            buffer: 10,
            currentDevice: localStorage.getItem('DEVICE_ID') ? localStorage.getItem('DEVICE_ID') : 0,
            currentDeviceName: localStorage.getItem('DEVICE_TITLE') ? localStorage.getItem('DEVICE_TITLE') : '',
            prevDeviceChange: '',
            open: false,
            openConfirm: false,
            taskUrl: '',
            statFull: [],
            dialogText: '',
            dialogContent: '',
            dialogConfirmType: '',
            dialogConfirmData: '',
            dialogData: '',
            editActive: false,
            treeData: [],
            labelWidth: 0,
            organization: localStorage.getItem('ORGANIZATION_ID') ? Number(localStorage.getItem('ORGANIZATION_ID')) : 'self',
            organizationsList: [],
            role: '',
            retryCount: 0,
            deviceStatLoading: true,
            updateTree: false,
            searchString: "",
            smartSortTree: localStorage.getItem('TREE_SMART_SORT') ? !!Number(localStorage.getItem('TREE_SMART_SORT')) : true,
            deviceButtons: null,
            deviceButtonsOptions: {
                node: false,
                unlink: false,
                active: false
            }
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('USER_JWT')) {
            window.location.hash = '/login'
        } else {
            if (this.state.organization === 'self' && localStorage.getItem('role') === 'super-admin') {

            } else {
                this.getDevicesTree(this.state.organization)
            }
        }

        if (localStorage.getItem('role') === 'super-admin') {
            this.getOrganizationsList();
            this.setState({
                labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
            })
        }
        if (this.state.currentDevice) {
            this.props.dispatch(getDevicesStatData({id: this.state.currentDevice, type: 'first'}));
        }
    }

    getDevicesTree = (organization) => {
        this.props.dispatch(getDevicesTreeData(organization));
    };
    getOrganizationsList = () => {
        this.props.dispatch(getOrganizations());
    };
    retryCounter = () => {
        this.setState({retryCount: this.state.retryCount + 1});
    };
    handleClose = () => {
        this.setState({
            open: false,
            openConfirm: false,
        });
    };
    handleConfirmAgree = (type = '') => {
        switch (type) {
            case 'activate-device':
                if (this.state.dialogData) {
                    this.props.dispatch(makeDevicesActivate(this.state.dialogData));
                }
                break;
            case 'reload-windows':
                this.reloadWindows();
                break;
            case 'reload-pos':
                this.reloadDevice();
                break;
            case 'delete-section':
                this.deleteSection(this.state.dialogConfirmData);
                break;
            case 'unlink-device':
                if (this.state.dialogData) {
                    this.props.dispatch(makeDevicesUnLink(this.state.dialogData));
                    if (this.state.dialogData === this.state.currentDevice) {
                        this.handleResetDevice();
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            openConfirm: false,
        });
    };

    handleConfirmOpen = (title, text, type, body = '') => {
        this.setState({
            openConfirm: true,
            dialogText: title,
            dialogContent: text,
            dialogConfirmType: type,
            dialogConfirmData: body,
        })
    };

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    handleChange = (name) => event => {
        switch (name) {
            case "smartSortTree":
                this.setState({
                    smartSortTree: !this.state.smartSortTree,
                });
                localStorage.setItem('TREE_SMART_SORT', +!this.state.smartSortTree);
                break;
            default:
                this.setState({
                    [name]: event.target.value,
                });
        }
    };

    handleChangeOrg = (event) => {
        this.setState({organization: event.value}, () => {
            this.getDevicesTree(event.value)
        });
        localStorage.setItem('ORGANIZATION_ID', event.value);
        localStorage.setItem('ORGANIZATION_NAME', event.name);
    };

    handleResetDevice = () => {
        this.setState({
            currentDevice: '',
            currentDeviceName: '',
        });
        localStorage.removeItem('DEVICE_ID');
        localStorage.removeItem('DEVICE_TITLE');
    };

    handlePickDevice = (device) => {
        console.log('this.state.currentDevice', this.state.currentDevice);
        if (device.id !== this.state.currentDevice) {
            let timeout = 0;
            if (this.state.currentDevice) {
                this.setState({
                    currentDevice: 'loading',
                    currentDeviceName: 'Loading...',
                });
                timeout = 600;
            }
            setTimeout(() => {
                localStorage.setItem('DEVICE_ID', device.id);
                localStorage.setItem('DEVICE_TITLE', device.title);
                this.setState({
                    currentDevice: device.id,
                    currentDeviceName: device.title,
                }, () => {
                    this.props.dispatch(clearDevicesData());
                    this.props.dispatch(getDevicesStatData({id: this.state.currentDevice, type: 'first'}));
                });
            }, timeout)
        }
        setTimeout(() => {
            if (this.state.currentDevice) {
                this.selectedCurrentDevice();
            }
        }, 1000)
    };

    handleActivateDevice = (device) => {
        this.setState({
            openConfirm: true,
            dialogText: this.props.t('Device activation'),
            dialogContent: this.props.t('Are you sure you want to activate the device') + ': ' + device.name,
            dialogConfirmType: 'activate-device',
            dialogData: device.id,
        })
    };

    handleUnlinkDevice = (device) => {
        this.setState({
            openConfirm: true,
            dialogText: this.props.t('Unbind device'),
            dialogContent: this.props.t('Are you sure you want to unbind the device') + ': ' + device.name,
            dialogConfirmType: 'unlink-device',
            dialogData: device.id,
            deviceButtons: null
        })
    };

    smartSortTree = (a, b) => {
        if (this.state.smartSortTree) {
            let sumA = 0;
            let sumB = 0;
            sumA = a.extra.status === 'online' ? 2 : sumA;
            sumB = b.extra.status === 'online' ? 2 : sumB;
            sumA = a.extra.active ? 1 : 0;
            sumB = b.extra.active ? 1 : 0;
            sumA = a.type === 'section' ? 3 : sumA;
            sumB = b.type === 'section' ? 3 : sumB;
            return sumB - sumA;
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.updateTree === true
            && this.props.updateTree !== prevProps.updateTree
        ) {
            this.getDevicesTree(this.state.organization);
            this.props.dispatch(dontUpdateTreeOrganizations());
        }
        if (
            prevProps.device.tree
            && (
                prevProps.device.tree !== this.props.device.tree
                || prevState.smartSortTree !== this.state.smartSortTree
            )

        ) {
            this.setState({
                treeData: getTreeFromFlatData({
                    flatData: _.get(this.props, 'device.tree', []).map(node => ({
                        ...node,
                        title: node.name,
                        subtitle: node.type === 'device' ? node.id : '',
                    })).sort(this.smartSortTree),
                    getKey: node => node.id,
                    getParentKey: node => node.parent,
                    rootKey: null,
                })
            }, () => {
                setTimeout(() => {
                    if (this.state.currentDevice) {
                        this.selectedCurrentDevice();
                    }
                }, 1000)
            })
        } else if (prevProps.device && prevProps.device.stat !== this.props.device.stat) {
            this.setState({
                prevDeviceChange: this.props.device.stat && this.props.device.stat.device_id
            });
            if (this.props.device.stat && this.props.device.stat.status === "running") {
                setTimeout(() => {
                    this.retryCounter();
                    this.props.dispatch(getDevicesStatData({id: this.state.currentDevice, type: 'again'}));
                }, 2000)
            } else if (this.props.device.stat && this.props.device.stat.status === "completed") {
                this.setState({
                    deviceStatLoading: false,
                    statFull: _.get(this.props, 'device.stat.result', {}),
                }, () => {
                    setTimeout(() => {
                        this.props.dispatch(getDevicesStatData({id: this.state.currentDevice, type: 'first'}));
                    }, 5000)
                })
            }
        } else if (prevProps.user && prevProps.user !== this.props.user) {
            this.setState({
                role: this.props.user.loginData.user.role
            }, () => {
                if (this.state.role === 'super-admin') {
                    this.getOrganizationsList();
                }
            });
        }

        if (prevProps.currentDevice !== this.props.currentDevice) {
            this.setState({
                currentDevice: 0,
                currentDeviceName: '',
            })
        }
    }

    sendDeviceChange = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}/api/devices/${this.state.changedSection}/`, {
            section: this.state.newParent
        }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                }, err => {
                    console.log('err', err);
                }
            )
    };
    reloadDevice = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${this.state.currentDevice}/reboot/`, {}, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                    this.setState({
                        dialogText: 'Reload POS starting, it can take a few minutes',
                        open: true
                    }, () => {
                        this.reloadDeviceTask(res.data.location)
                    })

                }, err => {
                    console.log('err', err);
                }
            )
    };
    reloadDeviceTask = (location) => {
        axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                    if (res.data.status === "running") {
                        setTimeout(() => {
                            this.reloadDeviceTask(location)
                        }, 10000)
                    } else if (res.data.status === "completed") {
                        setTimeout(() => {
                            this.handleClose()
                        }, 4000)

                    }
                }, err => {
                    console.log('err', err);
                }
            )
    };
    reloadWindows = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/devices/${this.state.currentDevice}/reboot_soft/`, {}, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                    this.setState({
                        dialogText: this.props.t('Reload Windows starting, it can take a few minutes'),
                        open: true
                    }, () => {
                        this.reloadWindowsTask(res.data.location)
                    })

                }, err => {
                    console.log('err', err);
                }
            )
    };
    reloadWindowsTask = (location) => {
        axios.get(`${process.env.REACT_APP_API_URL}${location}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                    if (res.data.status === "running") {
                        setTimeout(() => {
                            this.reloadWindowsTask(location)
                        }, 10000)
                    } else if (res.data.status === "completed") {
                        setTimeout(() => {
                            this.handleClose()
                        }, 4000)
                    }
                }, err => {
                    console.log('err', err);
                }
            )
    };
    sendParentChange = () => {
        axios.patch(`${process.env.REACT_APP_API_URL}/api/organizations/${this.state.organization}/sections/${this.state.changedSection}/`, {
            parent: this.state.newParent
        }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                }, err => {
                    console.log('err', err);
                }
            )
    };
    deleteSection = (id) => {
        this.props.dispatch(deleteSectionOrganizations({
            id: this.state.organization,
            section: id,
        }));
    };
    sendSectionNameChange = (title, id) => {
        this.props.dispatch(renameSectionOrganizations({
            id: this.state.organization,
            section: id,
            name: title,
        }));
    };
    sendDeviceNameChange = (title, id) => {
        axios.patch(`${process.env.REACT_APP_API_URL}/api/devices/${id}/`, {
            name: title
        }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                }, err => {
                    console.log('err', err);
                }
            )
    };
    makeNewSection = (name, parent) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/organizations/${this.state.organization}/sections/`, {
            name,
            parent
        }, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('USER_JWT')}`
            }
        })
            .then(res => {
                    this.getDevicesTree(this.state.organization)

                }, err => {
                    console.log('err', err);
                }
            )
    };

    handleSearchOnChange = e => {
        this.setState({
            searchString: e.target.value,
        });
    };

    handleSearchClear = () => {
        this.setState({
            searchString: '',
        });
    };

    selectedCurrentDevice = () => {
        // TODO: Переделать это, нужно корректно выбирать текущий девайс
        const prevSearchQuery = this.state.searchString;
        this.setState({
            searchString: this.state.currentDevice
        }, () => {
            this.setState({
                searchString: prevSearchQuery
            })
        });
        let list = document.querySelectorAll('.rst__node');
        for (let i = 0; i < list.length; i++) {
            list[i].classList.remove('current');
        }

        let el = document.querySelector('.rst__row.current');
        if (el) {
            while ((el = el.parentElement) && !el.classList.contains('rst__node')) ;
            el.classList.add('current');
        }
    };

    render() {
        const recordCall = (name, args) => {
            this.setState({
                newParent: args.nextParentNode === null ? null : args.nextParentNode.id,
                changedSection: args.node.id,
            }, () => {
                if (args.node.type === 'section') {
                    this.sendParentChange()
                } else if (args.node.type === 'device') {
                    this.sendDeviceChange()
                }

            })
        };

        const {classes, t} = this.props;
        const {
            currentDevice,
            currentDeviceName,
            deviceButtons,
            deviceButtonsOptions
        } = this.state;
        const getNodeKey = ({treeIndex}) => treeIndex;

        let options = [
            {
                name: localStorage.getItem('ORGANIZATION_NAME') ? localStorage.getItem('ORGANIZATION_NAME') : "",
                value: localStorage.getItem('ORGANIZATION_ID') ? Number(localStorage.getItem('ORGANIZATION_ID')) : "",
            }
        ];

        // translated into the desired data format for api&react
        if (typeof this.props.organizationsList == 'object') {
            let organizationsList = Object.values(this.props.organizationsList);
            if (organizationsList.length > 0) {
                options = JSON.parse(JSON.stringify(organizationsList), function (prop, value) {
                    switch (prop) {
                        case "id":
                            this.value = value;
                            return;
                        default:
                            return value;
                    }
                })
            }
        }

        const deviceButtonsOpen = (node, unlink, active) =>(event) => {
            this.setState({
                deviceButtons: event.currentTarget,
                deviceButtonsOptions: {
                    node,
                    unlink,
                    active
                }
            });
        };

        const deviceButtonsMenu = (node, unlink, active) => (
            <IconButton
                onClick={ deviceButtonsOpen(node, unlink, active) }
            >
                <MoreVertIcon />
            </IconButton>
        );

        const goToReports = (link, device) => {
            localStorage.setItem('DEVICE_ID', device.id);
            localStorage.setItem('DEVICE_TITLE', device.title);
            window.location.hash = link;
        };

        return (
            <div className="dashboard">
                <MenuAppBar/>
                <Grid
                    container
                    spacing={6}
                    className={classes.root}
                >
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        className="dashboard__left"
                    >
                        <Grid>
                            <h2>{t('Organization')}
                                {localStorage.getItem('role') === 'super-admin' ?
                                    <div className="dashboard__left__orgsearch">
                                        <form className={classes.root} autoComplete="off">
                                            <FormControl
                                                variant="outlined"
                                                className="dashboard__left__orgsearch__form"
                                            >
                                                <InputLabel
                                                    ref={ref => {
                                                        this.InputLabelRef = ref;
                                                    }}
                                                    htmlFor="organization"
                                                >
                                                </InputLabel>
                                                <SelectSearch
                                                    options={options}
                                                    value={this.state.organization === 'self' ? '' : this.state.organization}
                                                    name="organization_name"
                                                    placeholder={t('Organization name')}
                                                    onChange={this.handleChangeOrg}
                                                />
                                            </FormControl>
                                        </form>
                                    </div>
                                    : ''}
                            </h2>
                            {(localStorage.getItem('role') === 'super-admin' && this.state.organization !== 'self') || localStorage.getItem('role') !== 'super-admin' ?
                                <div>
                                    <Paper elevation={2} className={classes.searchSection}>
                                        <Grid container spacing={0} className={classes.searchGrid}>
                                            <Grid item xs={11}>
                                                <InputBase
                                                    className={classes.searchInput}
                                                    placeholder={t('UDID or device name')}
                                                    onChange={this.handleSearchOnChange}
                                                    value={this.state.searchString}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                {this.state.searchString ?
                                                    <IconButton style={{float: "right"}} aria-label="Clear"
                                                                onClick={this.handleSearchClear}>
                                                        <CancelIcon/>
                                                    </IconButton>
                                                    :
                                                    <IconButton style={{float: "right"}} aria-label="Search">
                                                        <SearchIcon/>
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <div>
										 <span>
                                     		{t('Smart Sort')}
										</span>
                                        <Switch
                                            checked={!!this.state.smartSortTree}
                                            onChange={this.handleChange('smartSortTree')}
                                        />
                                        <TooltipTap title={t('Smart Sort Desc')} placement="top" disableFocusListener
                                                    disableTouchListener>
                                            <IconButton aria-label="Delete" style={{padding: "2px"}}>
                                                <HelpIcon fontSize="small"/>
                                            </IconButton>
                                        </TooltipTap>
                                    </div>
                                    <Paper elevation={2} style={{paddingTop: 20, paddingBottom: 20}}>
                                        <div style={{height: 500}}>
                                            <SortableTree
                                                treeData={this.state.treeData}
                                                className={this.state.editActive ? 'editMode' : 'viewMode'}
                                                onChange={treeData => this.setState({treeData})}
                                                canDrop={({nextParent}) => !nextParent || !nextParent.noChildren}
                                                canNodeHaveChildren={node => node.type !== 'device'}
                                                searchQuery={this.state.searchString}
                                                onMoveNode={args => {
                                                    recordCall('onMoveNode', args);
                                                    setTimeout(() => {
                                                        if (currentDevice) {
                                                            this.selectedCurrentDevice();
                                                        }
                                                    }, 1000);
                                                    const {prevPath, nextPath, node} = args;
                                                    this.setState({
                                                        lastMovePrevPath: prevPath,
                                                        lastMoveNextPath: nextPath,
                                                        lastMoveNode: node,
                                                    });
                                                }}
                                                generateNodeProps={({node, path}) => (
                                                    node.type === 'device' && !this.state.editActive && node.extra.status === 'online' && node.extra.active ? {
                                                        className: `device ${node.extra.status} ${localStorage.getItem('DEVICE_ID') === node.id ? 'current' : ''}`,
                                                        id: node.id,
                                                        onClick: () => {
                                                            this.handlePickDevice(node);
                                                        },
                                                        buttons: [
                                                            deviceButtonsMenu(node, 1)
                                                        ],

                                                    } : node.type === 'device' && !this.state.editActive && node.extra.status === 'offline' && node.extra.active ? {
                                                        className: 'device ' + node.extra.status,
                                                        id: node.id,
                                                        buttons: [
                                                            deviceButtonsMenu(node, 1)
                                                        ],
                                                    } : node.type === 'device' && !this.state.editActive && !node.extra.active ? {
                                                        className: 'device not-active',
                                                        id: node.id,
                                                        buttons: [
                                                            deviceButtonsMenu(node, 1, 1)
                                                        ],
                                                    } : node.type === 'section' && this.state.editActive ? {
                                                        className: 'section',
                                                        buttons: [
                                                            <IconButton aria-label="Create new folder" onClick={() => {
                                                                this.makeNewSection(`New section`, node.id);
                                                                this.setState(state => ({
                                                                    treeData: addNodeUnderParent({
                                                                        treeData: state.treeData,
                                                                        parentKey: path[path.length - 1],
                                                                        expandParent: true,
                                                                        getNodeKey,
                                                                        newNode: {
                                                                            title: `New Section`,
                                                                            type: 'section'
                                                                        },
                                                                        addAsFirstChild: state.addAsFirstChild,
                                                                    }).treeData,
                                                                }))
                                                            }
                                                            }>
                                                                <CreateNewFolder/>
                                                            </IconButton>,
                                                            <IconButton aria-label="Delete" onClick={() => {
                                                                this.handleConfirmOpen(
                                                                    t('Delete section'),
                                                                    `${t('Delete section confirm')}: ${node.name} ?`,
                                                                    'delete-section',
                                                                    node.id
                                                                )
                                                            }
                                                            }>
                                                                <DeleteIcon/>
                                                            </IconButton>,
                                                            <IconButton aria-label="Create new folder"
                                                                        style={{borderRadius: '7px'}}
                                                                        onClick={() => {
                                                                            console.log('node', node);
                                                                            this.sendSectionNameChange(node.title, node.id)
                                                                        }
                                                                        }>
                                                                <SaveIcon/>
                                                                <span style={{
                                                                    display: 'inline-block',
                                                                    fontSize: 14,
                                                                    paddingLeft: '10px'
                                                                }}>
																{t('Save')}
															</span>
                                                            </IconButton>
                                                        ], title: (
                                                            <Input
                                                                style={{fontSize: '1.1rem'}}
                                                                value={node.title}
                                                                onChange={event => {
                                                                    const title = event.target.value;
                                                                    this.setState(state => ({
                                                                        treeData: changeNodeAtPath({
                                                                            treeData: state.treeData,
                                                                            path,
                                                                            getNodeKey,
                                                                            newNode: {...node, title},
                                                                        }),
                                                                    }));
                                                                }}
                                                            />
                                                        )
                                                    } : this.state.editActive && node.type === 'device' ? {
                                                        className: 'device',
                                                        buttons: [
                                                            <IconButton aria-label="Create new folder"
                                                                        style={{borderRadius: '7px'}}
                                                                        onClick={() => {
                                                                            console.log('node', node);
                                                                            this.sendDeviceNameChange(node.title, node.id)
                                                                        }
                                                                        }>
                                                                <SaveIcon/>
                                                                <span style={{
                                                                    display: 'inline-block',
                                                                    fontSize: 14,
                                                                    paddingLeft: '10px'
                                                                }}>
																{t('Save')}
															</span>
                                                            </IconButton>
                                                        ],
                                                        title: (
                                                            <Input
                                                                className="inputEdit"
                                                                value={node.title}
                                                                onChange={event => {
                                                                    const title = event.target.value;
                                                                    this.setState(state => ({
                                                                        treeData: changeNodeAtPath({
                                                                            treeData: state.treeData,
                                                                            path,
                                                                            getNodeKey,
                                                                            newNode: {...node, title},
                                                                        }),
                                                                    }));
                                                                }}
                                                            />
                                                        )
                                                    } : node.type === 'section' ? {
                                                        className: 'section',
                                                    } : ''
                                                )}
                                            />
                                        </div>
                                        <div className="dashboard__left__bottom">
                                            <IconButton aria-label="Create new folder" style={{borderRadius: '7px'}}
                                                        onClick={() => {
                                                            this.makeNewSection(`New section`, null);
                                                            this.setState(state => ({
                                                                treeData: state.treeData.concat({
                                                                    title: `New section`,
                                                                    type: 'section'
                                                                }),
                                                            }))
                                                        }
                                                        }>
                                                <CreateNewFolder/>
                                                <span style={{
                                                    display: 'inline-block',
                                                    fontSize: 14,
                                                    paddingLeft: '10px'
                                                }}>
												{t('Add folder')}
										</span>
                                            </IconButton>
                                            {!this.state.editActive ?
                                                <IconButton aria-label="Create new folder" style={{borderRadius: '7px'}}
                                                            onClick={() =>
                                                                this.setState({
                                                                    editActive: !this.state.editActive
                                                                })
                                                            }>
                                                    <EditIcon/>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        fontSize: 14,
                                                        paddingLeft: '10px'
                                                    }}>
												{t('Edit mode')}
										</span>
                                                </IconButton> :
                                                <IconButton aria-label="Create new folder" style={{borderRadius: '7px'}}
                                                            onClick={() =>
                                                                this.setState({
                                                                    editActive: !this.state.editActive
                                                                })
                                                            }>
                                                    <VisibilityIcon/>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        fontSize: 14,
                                                        paddingLeft: '10px'
                                                    }}>
												{t('View mode')}
											</span>
                                                </IconButton>
                                            }
                                        </div>
                                    </Paper>
                                </div>
                                :
                                <Paper elevation={2} style={{paddingTop: 26, paddingBottom: 56}}>
                                    <Grid container
                                          spacing={8}
                                          alignItems="flex-start"
                                          justify="space-between"
                                          style={{paddingTop: 0, textAlign: 'center'}}>
                                        <h3 style={{display: 'block', width: '100%', fontSize: '28px', color: '#444'}}>
                                            {t('No organization selected')}
                                        </h3>
                                        <span
                                            style={{display: 'block', width: '100%', fontSize: '18px', color: '#ccc'}}>
                                {t('You should pick organization from list')}
                                </span>
                                    </Grid>
                                </Paper>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} className="dashboard__right">
                        <h2 style={localStorage.getItem('role') === 'super-admin' ? {paddingBottom: '42px'} : {}}>{t('Current terminal settings')}
                            <span
                                style={{color: '#6200ee'}}> {currentDeviceName}</span></h2>
                        {currentDevice && currentDevice !== 'loading' ?
                            <Paper elevation={2} style={{paddingTop: 26, paddingBottom: 26}}>

                                <DashboardStats
                                    statFull={this.state.statFull}
                                    deviceStatLoading={this.state.deviceStatLoading}
                                />

                                <div className="dashboard__right__bottom">
                                    <Link to={"/backup"} className={classes.link}>
                                        <Button className={classes.buttonLink}>
                                            {t('System Restore')}
                                        </Button>
                                    </Link>
                                    <Button
                                        onClick={() => this.handleConfirmOpen(
                                            t('Reload Windows'),
                                            t('Reload Windows confirm')
                                            , 'reload-windows'
                                        )}
                                        className={classes.buttonLink}
                                    >
                                        {t('Reload Windows')}
                                    </Button>
                                    <Button
                                        className={classes.buttonLink}
                                        onClick={() => this.handleConfirmOpen(
                                            t('Reload POs'),
                                            t('Reload POs confirm')
                                            , 'reload-pos'
                                        )}
                                    >
                                        {t('Reload POs')}
                                    </Button>
                                    <Link to={"/files"} className={classes.link}>
                                        <Button className={classes.buttonLink}>
                                            {t('File Manager')}
                                        </Button>
                                    </Link>
                                    <Link to={"/control"}>
                                        <Button variant="contained" color="secondary" className={classes.buttonAction}>
                                            {t('Manage')}
                                        </Button>
                                    </Link>
                                </div>
                            </Paper>
                            : currentDevice === 'loading' ?
                                <CircularProgress className={classes.progressBig} size={140}/>
                                : <Paper elevation={2} style={{paddingTop: 26, paddingBottom: 56}}>
                                    <Grid container
                                          spacing={8}
                                          alignItems="flex-start"
                                          justify="space-between"
                                          style={{paddingTop: 0, textAlign: 'center'}}>
                                        <h3 style={{
                                            display: 'block',
                                            width: '100%',
                                            fontSize: '28px',
                                            color: '#444'
                                        }}>
                                            {t('No selected terminal')}
                                        </h3>
                                        <span style={{
                                            display: 'block',
                                            width: '100%',
                                            fontSize: '18px',
                                            color: '#ccc'
                                        }}>
										{t('You should pick one from left column')}
									</span>
                                    </Grid>
                                </Paper>
                        }
                        {currentDevice ? <Accessories device={currentDevice}/> : ''}
                    </Grid>
                    { localStorage.getItem('role') === 'super-admin' ? <DashboardBottom/>: '' }
                </Grid>
                <Menu
                    id="device-menu"
                    anchorEl={ deviceButtons }
                    open={ Boolean(deviceButtons) }
                    onClose={ () => this.setState({ deviceButtons: null }) }
                >
                    { deviceButtonsOptions.unlink && (
                        <MenuItem
                            onClick={(event) => {
                                event.preventDefault();
                                this.handleUnlinkDevice(deviceButtonsOptions.node)
                            }}
                        >
                            {t('Unbind')}
                        </MenuItem>
                    ) }
                    { deviceButtonsOptions.active && (
                        <MenuItem
                            onClick={(event) => {
                                event.preventDefault();
                                this.handleActivateDevice(deviceButtonsOptions.node)
                            }}
                        >
                            {t('Activate')}
                        </MenuItem>
                    ) }
                    <MenuItem
                        onClick={() => goToReports('/report', deviceButtonsOptions.node)}>
                        {t('Reports')}
                    </MenuItem>

                    <MenuItem
                        onClick={() => goToReports('/report-accessories', deviceButtonsOptions.node)}
                    >
                        {t('Accessories reports')}
                    </MenuItem>
                </Menu>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dialogText}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" style={{
                            textAlign: 'center', margin: '20px' +
                                ' auto !important'
                        }}>
                            <CircularProgress className={classes.progress}/>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openConfirm}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.dialogText}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.dialogContent}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between', margin: '15px 20px' }}>
                        <Button variant="contained" color="secondary" onClick={this.handleClose}>
                            {t('Disagree')}
                        </Button>
                        <Button variant="contained" onClick={() => {
                            this.handleConfirmAgree(this.state.dialogConfirmType)
                        }} autoFocus style={{backgroundColor: 'green', color: "white"}}>
                            {t('Agree')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    device: state.deviceData.device,
    currentDevice: state.deviceData.currentDevice,
    currentDeviceName: state.deviceData.currentDeviceName,
    user: state.userData.user,
    organizationsList: state.organizationsData.organizationsList,
    updateTree: state.organizationsData.updateTree,
});
const composeComponent = compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(Dashboard);

export default composeComponent;
