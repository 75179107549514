import { combineReducers } from 'redux';
import userData from './userData';
import deviceData from './deviceData';
import organizationsData from "./organizationData";
import otherData from "./otherData";

const reducers = {
	userData,
	deviceData,
    organizationsData,
	otherData,
};

const appReducer = combineReducers(reducers);

const devices = (state, action) => {
	if (action.type === 'INIT_APP_STORE') {
		state = undefined;
	}

	return appReducer(state, action);
};

export default devices;
