import React, {Component} from 'react';

import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from "react-redux";
import generator from 'generate-password';

import {withStyles} from '@material-ui/core/styles';

import {
    Grid,
    Button,
    IconButton
} from '@material-ui/core';

import {
    AutorenewOutlined,
    Visibility,
    VisibilityOff,
    CheckCircleOutline
} from "@material-ui/icons";

import {
    UserChangePassword,
    UserClearChangePasswordStatus
} from "../../../Actions/user/userActions";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonAction: {
        background: '#6200ee',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: '100%',
    },
    alignBottom: {
        alignSelf: 'flex-end',
    },
    noPadding: {
        paddingTop: '0px'
    },
    center: {
        textAlign: 'center',
    },
});


class UserPassword extends Component {

    constructor(props) {
        super(props);

        this.clearStatusPassword();

        this._password = this.generatePassword();

        this.state = {
            oldPassword: '',
            password: this._password,
            confirmPassword: this._password,
            showPassword: true,
            showOldPassword: false,
            user: {
                statusOrganization: '',
                changePasswordStatus: '',
            }
        };
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => value === this.state.password);
    }

    generatePassword = () => {
        return generator.generate({
            length: 10,
            number: true,
        })
    };

    handleClickShowPassword = () => {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    handleClickShowOldPassword = () => {
        this.setState(state => ({showOldPassword: !state.showOldPassword}));
    };

    handleChange = (name) => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleClickGeneratedPassword = () => {
        const password = this.generatePassword();
        this.setState({
            password: password,
            confirmPassword: password,
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.dispatch(UserChangePassword({
            "old_password": this.state.oldPassword,
            "password": this.state.password,
            "password_confirm": this.state.confirmPassword,
        }));
    };

    clearStatusPassword = () => {
        this.props.dispatch(UserClearChangePasswordStatus());
    };

    render() {
        const {classes, t} = this.props;
        return (
            <ValidatorForm
                ref="form"
                onSubmit={this.handleSubmit}
                className={classes.container}
            >
                {this.props.user && this.props.user.changePasswordStatus === 'Success' ?
                    <div className={classes.center}>
                        <p>
                            <CheckCircleOutline style={{fontSize: 120}}/>
                        </p>
                        <h2>{t('Password successfully changed')}</h2>
                    </div>
                    :
                    <div>
                        <Grid container spacing={0} className={classes.noPadding}>
                            <Grid item xs={10}>
                                <TextValidator
                                    label={t('Old Password')}
                                    onChange={this.handleChange('oldPassword')}
                                    name="old-password"
                                    type={this.state.showOldPassword ? 'text' : 'password'}
                                    className={classes.textField}
                                    margin="normal"
                                    value={this.state.oldPassword}
                                    validators={['required']}
                                    errorMessages={[t('This field is required')]}
                                />
                            </Grid>
                            <Grid item xs={2} className={classes.alignBottom}>
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => this.handleClickShowOldPassword}
                                >
                                    {this.state.showOldPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} className={classes.noPadding}>
                            <Grid item xs={8}>
                                <TextValidator
                                    label={t('Password')}
                                    onChange={this.handleChange('password')}
                                    name="password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    className={classes.textField}
                                    margin="normal"
                                    value={this.state.password}
                                    validators={['required']}
                                    errorMessages={[t('This field is required')]}

                                />
                            </Grid>

                            <Grid item xs={2} className={classes.alignBottom}>
                                <IconButton
                                    aria-label="Generated password"
                                    onClick={this.handleClickGeneratedPassword}
                                >
                                    <AutorenewOutlined/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={2} className={classes.alignBottom}>
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </Grid>

                        </Grid>

                        <Grid container spacing={0} className={classes.noPadding}>
                            <Grid item xs={12}>
                                <TextValidator
                                    label={t('Confirm Password')}
                                    onChange={this.handleChange('confirmPassword')}
                                    name="confirm-password"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    className={classes.textField}
                                    margin="normal"
                                    value={this.state.confirmPassword}
                                    validators={['isPasswordMatch', 'required']}
                                    errorMessages={[t("Passwords don't match"), t('This field is required')]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} className={classes.root}>
                            <Grid item xs={12}>
                                <Button variant="contained" color="secondary"
                                        className={classes.buttonAction} type="submit">
                                    {t('Change')}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                }
            </ValidatorForm>
        );
    }
}

const mapStateToProps = state => ({
    user: state.userData.user,
});
export default compose(
    connect(mapStateToProps),
    withStyles(styles),
    withNamespaces(),
)(UserPassword);
